<template>
  <div>
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" /> 

    <div class="content">
      <form-data ref="formBalanceCairan" />
    </div>
  </div>
</template>

<script>
import FormData from './FormData.vue'
import Gen from '@/libs/Gen'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'

const _ = global._

export default {
  components: {
    FormData, PatientInfo
  },
  data(){
    return {
      tekananDarah: {
        data: {}
      },
      balanceCairan: {},
      ews: {},
      hermodinamy: {
        data: {},
      },

      tHistoryData:{
        data:[],
        page:{
          total_data:0,
          per_page: 10,
          current_page:1,
          is_loading:false
        }
      },

      pageNoTData: 1,


      row: {},
      mrValidation: {},
      mrCairan: [],
      mrDarahBalanceCairan: [],

      loading: {
        patientInfo: false
      },

      patientData: {},
      formData: {},
    }
  },

  mounted() {
    this.getFormData()
    this.getPatientInfo()
  },

  methods: {
    getHistoryData(page=1){
      this.$set(this.tHistoryData.page, 'is_loading', true)
      Gen.apiRest(
        "/get/"+'RanapBalanceCairan',
        {
          params: {
            page,
            type: 'get-history',
            id_registrasi: this.$route.query.regId
          }
        },
      ).then(res=>{
        const { historyData } = res.data
        this.$set(this, 'tHistoryData', historyData)

        this.$set(this.tHistoryData.page, 'is_loading', false)
      })
    },

    getPatientInfo(){
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/"+'RanapPasienPerawatan',
        { 
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          } 
        }, 
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)
        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getFormData(){
      Gen.apiRest(
        "/get/"+'RanapBalanceCairan',
        {
          params: {
            type: 'get-form',
            id_registrasi: this.$route.query.regId
          }
        },
      ).then(res=>{
        const { 
          row, mrCairan, mrDarahBalanceCairan, mrValidation
        }  = res.data

        this.$set(this, 'formData', row)
        this.$set(this, 'mrCairan', mrCairan)
        this.$set(this, 'mrDarahBalanceCairan', mrDarahBalanceCairan)
        this.$set(this, 'mrValidation', mrValidation)
        const compFormBalanceCairan = this.$refs.formBalanceCairan
        compFormBalanceCairan.setFormData(row)
      })
    },

    doSubmitBalanceCairan(data){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/"+'RanapBalanceCairan',
        { 
          data: {
            ...data,
            id_registrasi: this.$route.query.regId
          } 
        }, 
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$router.back()
          }
        })
      }).catch(()=>{
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },

    changePage(page){
      this.$set(this, 'pageNoTData', page)
    }
  },

  computed: {
    parentData(){
      return this.$parent
    },
    moduleRole(){
      return this.parentData.moduleRole
    }
  },

  watch: {
    'parentData.viewType'(v){
      this.getFormData()
    },
    'pageNoTData'(v){
      this.getHistoryData(v)
    }
  }
}
</script>