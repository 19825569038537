<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />

    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <h5 class="card-title font-weight-semibold">Form Pemantauan Durante Operasi</h5>
            </div>
            
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="wrap_width_table">
                    <div class="table-responsive">
                      <table class="table  table-sm  table-bordered">
                        <tbody>
                          <tr>
                            <td>Penempatan Pasien </td>
                            <td>
                              <b-form-radio-group
                                :options="Config.mr.mDurPenempatan"
                                v-model="row.arando_penempatan_pasien"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Pengaturan Posisi Operasi</td>
                            <td>
                              <b-form-radio-group
                                class="radio-inline"
                                :options="Config.mr.mDurPosisi"
                                v-model="row.arando_posisi_operasi"
                              />
                              <div v-if="row.arando_posisi_operasi == 'Lain-Lain'" style="width: 210px;">
                                <b-form-input v-model="row.arando_posisi_operasi_text" type="text" class="form-control">
                                </b-form-input>
                                <VValidate name="Pengaturan Posisi Operasi" v-model="row.arando_posisi_operasi_text"
                                  :rules="{required: 1}" />
                              </div>
                            </td>
                          </tr>


                          <tr>
                            <td>Preparasi Operasi</td>
                            <td>
                              <table class="table  table-sm  table-bordered">
                                <tbody>
                                  <tr>
                                    <td>
                                     <b-form-checkbox-group
                                        class="checkbox-block"
                                        v-model="row.arando_prep_operasi"
                                        :options="Config.mr.mDurPrepOperasi"
                                        name="arando_prep_operasi"
                                      ></b-form-checkbox-group>
                                    </td>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_prep_is_desinfektan"
                                        name="arando_prep_is_desinfektan"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Desinfektan:
                                      </b-form-checkbox>

                                      <div class="ml-4" v-if="row.arando_prep_is_desinfektan == 'Y'">
                                        <b-form-checkbox-group
                                          class="checkbox-block"
                                          v-model="row.arando_prep_desinfektan"
                                          :options="Config.mr.mDurPrepDesinfektan"
                                          name="arando_prep_desinfektan"
                                        ></b-form-checkbox-group>

                                        <div style="width:50%;" v-if="(row.arando_prep_desinfektan||[]).indexOf('Lain–lain') != -1">
                                          <b-form-input v-model="row.arando_prep_desinfektan_text" type="text" class="form-control form-control-sm" placeholder=""/>
                                          <VValidate name="Desinfektan Lainnya" v-model="row.arando_prep_desinfektan_text"
                                            :rules="{required: 1}" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_prep_is_penutup_luka"
                                        name="arando_prep_is_penutup_luka"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Penutup Luka:
                                      </b-form-checkbox>

                                      <div class="ml-4" v-if="row.arando_prep_is_penutup_luka == 'Y'">
                                        <b-form-checkbox-group
                                          class="checkbox-block"
                                          v-model="row.arando_prep_penutup_luka"
                                          :options="Config.mr.mDurPrepPenutupLuka"
                                          name="arando_prep_penutup_luka"
                                        ></b-form-checkbox-group>
                                      </div>
                                    </td>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_is_prep_penyeritan"
                                        name="arando_is_prep_penyeritan"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Penyeritilan instrument menggunakan:
                                      </b-form-checkbox>
  
                                      <div class="ml-4" v-if="row.arando_is_prep_penyeritan == 'Y'">
                                        <b-form-checkbox-group
                                          class="checkbox-block"
                                          v-model="row.arando_prep_penyeritan"
                                          :options="Config.mr.mDurPrepPenyeteril"
                                          name="arando_prep_penyeritan"
                                        ></b-form-checkbox-group>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_is_prep_klarifikasi"
                                        name="arando_is_prep_klarifikasi"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Klasifikasi:
                                      </b-form-checkbox>

                                      <div class="ml-4" v-if="row.arando_is_prep_klarifikasi == 'Y'">
                                        <b-form-checkbox-group
                                          class="checkbox-block"
                                          v-model="row.arando_prep_klarifikasi"
                                          :options="Config.mr.mDurPrepKlasifikasi"
                                          name="arando_prep_klarifikasi"
                                        ></b-form-checkbox-group>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Perhitungan Instrument Dasar</th>
                            <th>
                              <b-form-textarea v-model="row.arando_prep_instrumen_dasar" rows="2" class="form-control"></b-form-textarea>
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>

                </div>

                <div class="col-md-12 mt-2">
                  <div class="wrap_width_table">
                    <div class="table-responsive">
                      <table class="table table-input table-sm  table-bordered">
                        <thead class="text-center">
                          <tr>
                            <th style="width:250px;">Jenis Item</th>
                            <th>Hitungan Pertama</th>
                            <th colspan="3">Tambahan Selama Operasi</th>
                            <th>Jumlah</th>
                            <th>Hitungan Kedua</th>
                            <th>Hitungan Terakhir</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(v,k) in (row.arando_item_data||[])" :key="k+'item_data'">
                            <td>{{v.jenis}}</td>
                            <td class="input-cell"><b-form-input @input="hitungDurante(k)" :formatter="numberOnly" v-model="row.arando_item_data[k]['hitungan_pertama']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input @input="hitungDurante(k)" :formatter="numberOnly" v-model="row.arando_item_data[k]['tambahan1']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input @input="hitungDurante(k)" :formatter="numberOnly" v-model="row.arando_item_data[k]['tambahan2']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input @input="hitungDurante(k)" :formatter="numberOnly" v-model="row.arando_item_data[k]['tambahan3']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input @input="hitungDurante(k)" :formatter="numberOnly" v-model="row.arando_item_data[k]['jumlah']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input @input="hitungDurante(k)" :formatter="numberOnly" v-model="row.arando_item_data[k]['hitungan_kedua']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input @input="hitungDurante(k)" :formatter="numberOnly" v-model="row.arando_item_data[k]['hitungan_terakhir']" type="text" class="form-control" /></td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr class="table-info">
                            <td colspan="8"><small class="font-weight-semibold text-info-800">Item Lainnya</small></td>
                          </tr>
                          <tr v-for="(v,k) in (row.arando_item_data_lainnya||[])" :key="k+'item_lainnya'">
                            <td>
                              <div class="input-group input-group-sm">
                                <b-form-input v-model="row.arando_item_data_lainnya[k]['jenis']" type="text" class="form-control" />
                                <div class="input-group-append"><a @click="row.arando_item_data_lainnya.splice(k,1)" href="javascript:;" v-b-tooltip.hover title="Hapus Item" class="btn alpha-danger border-danger"><i class="icon-trash-alt text-danger-800"></i></a></div>
                              </div>
                            </td>
                            <td class="input-cell"><b-form-input v-model="row.arando_item_data_lainnya[k]['hitungan_pertama']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input v-model="row.arando_item_data_lainnya[k]['tambahan1']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input v-model="row.arando_item_data_lainnya[k]['tambahan2']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input v-model="row.arando_item_data_lainnya[k]['tambahan3']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input v-model="row.arando_item_data_lainnya[k]['jumlah']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input v-model="row.arando_item_data_lainnya[k]['hitungan_kedua']" type="text" class="form-control" /></td>
                            <td class="input-cell"><b-form-input v-model="row.arando_item_data_lainnya[k]['hitungan_terakhir']" type="text" class="form-control" /></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="8" class="text-center">
                              <a href="javascript:;" @click="addItemTambahan()" class="btn alpha-info border-info text-info-800">
                                <i class="icon-plus2"></i>
                                <span class="ml-1">Tambah Item Lainnya</span>
                              </a>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="wrap_width_table">
                    <div class="table-responsive">
                      <table class="table  table-sm  table-bordered">
                        <thead>
                          <tr>
                            <th colspan="2">Pemakaian Alat</th>
                            <th>Aksi</th>
                            <th style="width:65%;">Ya</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1 </td>
                            <td>ET</td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_et_aksi"
                              />
                            </td>

                            <td v-if="row.arando_et_aksi == 'Y'">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                    </div>
                                    <datepicker :clear-button="true" input-class="form-control transparent" 
                                        calendar-class="my-datepicker"
                                        v-model="row.arando_et_tanggal">
                                    </datepicker>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="input-group bootstrap-timepicker timepicker mb-2">
                                    <span class="input-group-prepend input-group-addon">
                                      <span class="input-group-text"><i class="fa-solid fa-clock"></i></span>
                                    </span>
                                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_et_jam">
                                    </vue-timepicker>
                                  </div>
                                </div>
                              </div>

                              <div class="d-flex">
                                <b-form-checkbox-group
                                    id="arando_et_ya"
                                    class="checkbox-inline"
                                    v-model="row.arando_et_ya"
                                    :options="Config.mr.mDurET"
                                    name="arando_et_ya"
                                ></b-form-checkbox-group>
                                <div class="ml-2" style="width:30%;" v-if="(row.arando_et_ya||[]).indexOf('No. ET') != -1">
                                  <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arando_et_ya_text"
                                  :options="noET" label="text"
                                  :clearable="true" :reduce="v=>v.value"></v-select>
                                  <VValidate name="No. ET" v-model="row.arando_et_ya_text"
                                    :rules="{required: 1}" />
                                </div>
                              </div>
                            </td>
                            <td v-else> - </td>
                          </tr>
                          <tr>
                            <td>2 </td>
                            <td>Diatermi </td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_diatermi_aksi"
                              />
                            </td>


                            <td v-if="row.arando_diatermi_aksi == 'Y'">
                              <b-form-checkbox-group
                                  id="arando_diatermi_ya"
                                  class="checkbox-inline"
                                  v-model="row.arando_diatermi_ya"
                                  :options="Config.mr.mDurDiatermi"
                                  name="arando_diatermi_ya"
                              ></b-form-checkbox-group>

                              <b-form-checkbox
                                v-model="row.arando_diatermi_lokasi"
                                name="arando_diatermi_lokasi"
                                value="Y"
                                unchecked-value="N"
                              >
                                Lokasi:
                              </b-form-checkbox>
                              <div class="custom-control mt-2 custom-checkbox">
                                <div class="mt-2">
                                  <b-form-checkbox-group
                                    id="arando_diatermi_lokasi_data"
                                    class="checkbox-inline"
                                    v-model="row.arando_diatermi_lokasi_data"
                                    :options="Config.mr.mDurDiatermiLokasi"
                                    name="arando_diatermi_lokasi_data"
                                  ></b-form-checkbox-group>
                                </div>
                              </div>
                            </td>
                            <td v-else> - </td>
                          </tr>
                          <tr>
                            <td>3 </td>
                            <td>Section Pump</td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_section_pump_aksi"
                              />
                            </td>

                            <td>

                            </td>
                          </tr>

                          <tr>
                            <td>4 </td>
                            <td>Infus</td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_infus_aksi"
                              />
                            </td>

                            <td v-if="row.arando_infus_aksi == 'Y'">
                              <table class="table  table-sm  table-bordered">
                                <tbody>

                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_is_needle_no"
                                        name="arando_is_needle_no"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Needle No
                                      </b-form-checkbox>
                                    </td>

                                    <td>
                                      <b-form-input :formatter="number" v-model="row.arando_needle_no" type="text" class="form-control form-control-sm"/>
                                    </td>

                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_is_cairan"
                                        name="arando_is_cairan"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Jenis Cairan
                                      </b-form-checkbox>
                                    </td>
                                    
                                    <td>
                                      <div>
                                        <b-form-input v-model="row.arando_jumlah_total" type="text" class="form-control" placeholder="Nama Cairan"/>

                                        <!--
                                        <b-form-checkbox
                                          v-model="row.arando_is_kolaid"
                                          name="arando_is_kolaid"
                                          value="Y"
                                          unchecked-value="N"
                                        >
                                          Koloid (Albumin 5 % )
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                          v-model="row.arando_is_kristaloid"
                                          name="arando_is_kristaloid"
                                          value="Y"
                                          unchecked-value="N"
                                        >
                                          Kristaloid (Rl , NaCI, Dekstrose 5%)
                                        </b-form-checkbox>
                                        
                                        <b-form-checkbox
                                          v-model="row.arando_is_jumlah_total"
                                          name="arando_is_jumlah_total"
                                          value="Y"
                                          unchecked-value="N"
                                        >
                                          Jumlah total
                                        </b-form-checkbox>
                                        
                                        <div class="input-group mt-2" v-if="row.arando_is_jumlah_total == 'Y'">
                                          <b-form-input v-model="row.arando_jumlah_total" :formatter="numberOnly" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">cc</span>
                                          </div>
                                                    
                                          <VValidate name="Jumlah Total" v-model="row.arando_jumlah_total"
                                            :rules="{required: 1}" />
                                        </div>
                                        -->


                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_is_tanggal"
                                        name="arando_is_tanggal"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Tanggal
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <div>
                                        <div class="input-group mb-2">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                          </div>
                                          <datepicker :clear-button="true" input-class="form-control transparent" 
                                              calendar-class="my-datepicker"
                                              v-model="row.arando_tanggal">
                                          </datepicker>
                                        </div>

                                        <div class="input-group bootstrap-timepicker timepicker mb-2">
                                          <span class="input-group-prepend input-group-addon">
                                            <span class="input-group-text"><i class="fa-solid fa-clock"></i></span>
                                          </span>
                                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_jam">
                                          </vue-timepicker>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td v-else> - </td>
                          </tr>

                          <tr>
                            <td>5 </td>
                            <td>Transfusi Darah</td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_transfusi_aksi"
                              />
                            </td>

                            <td v-if="row.arando_transfusi_aksi == 'Y'">
                              <table class="table  table-sm  table-bordered">
                                <tbody>
                                  <tr>
                                    <td colspan="2">
                                      <b-form-checkbox
                                        v-model="row.arando_is_informed_consent"
                                        name="arando_is_informed_consent"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Informed Consent Tranfusi Darah
                                      </b-form-checkbox>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_whole_blood_is"
                                        name="arando_whole_blood_is"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Whole Blood
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <b-form-input v-model="row.arando_whole_blood_kolf" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">Kolf</span>
                                        </div>
                                      </div>
                                    </td>
                                    <td width="50%">
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <span class="input-group-prepend input-group-addon">
                                          <span class="input-group-text">Masuk Jam</span>
                                        </span>
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_whole_blood_masuk_jam">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_packaged_cell_is"
                                        name="arando_packaged_cell_is"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Packed Cell DTC
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <b-form-input v-model="row.arando_packaged_cell_kolf" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">Kolf</span>
                                        </div>
                                      </div>
                                    </td>
                                    <td width="50%">
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <span class="input-group-prepend input-group-addon">
                                          <span class="input-group-text">Masuk Jam</span>
                                        </span>
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_packaged_cell_masuk_jam">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_packaged_ffp_is"
                                        name="arando_packaged_ffp_is"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        FFP
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <b-form-input v-model="row.arando_packaged_ffp_kolf" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">Kolf</span>
                                        </div>
                                      </div>
                                    </td>
                                    <td width="50%">
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <span class="input-group-prepend input-group-addon">
                                          <span class="input-group-text">Masuk Jam</span>
                                        </span>
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_packaged_ffp_masuk_jam">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                     <td>
                                      <b-form-checkbox
                                        v-model="row.arando_packaged_tc_is"
                                        name="arando_packaged_tc_is"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        TC
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <b-form-input v-model="row.arando_packaged_tc_kolf" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">Kolf</span>
                                        </div>
                                      </div>
                                    </td>
                                    <td width="50%">
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <span class="input-group-prepend input-group-addon">
                                          <span class="input-group-text">Masuk Jam</span>
                                        </span>
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_packaged_tc_masuk_jam">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="2">
                                      <b-form-checkbox
                                        v-model="row.arando_ceklist_transfusi"
                                        name="arando_ceklist_transfusi"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Check list Transfusi
                                      </b-form-checkbox>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td v-else> - </td>
                          </tr>

                          <tr>
                            <td>6 </td>
                            <td>DC</td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_dc_aksi"
                              />
                            </td>

                            <td v-if="row.arando_dc_aksi == 'Y'">

                               <div class="row">
                                <div class="col-md-4">
                                  <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                    </div>
                                    <datepicker :clear-button="true" input-class="form-control transparent" 
                                        calendar-class="my-datepicker"
                                        v-model="row.arando_dc_tanggal">
                                    </datepicker>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="input-group bootstrap-timepicker timepicker mb-2">
                                    <span class="input-group-prepend input-group-addon">
                                      <span class="input-group-text"><i class="fa-solid fa-clock"></i></span>
                                    </span>
                                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_dc_jam">
                                    </vue-timepicker>
                                  </div>
                                </div>
                              </div>

                              <table class="table  table-sm  table-bordered">
                                <tbody>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_dc_no_foley_cateter"
                                        name="arando_dc_no_foley_cateter"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        No Foley Cateter
                                      </b-form-checkbox>
                                    </td>

                                    <td>
                                      <b-form-input v-model="row.arando_dc_no_foley_cateter_text" :formatter="numberOnly" type="text" class="form-control">
                                      </b-form-input>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_dc_jenis_cateter"
                                        name="arando_dc_jenis_cateter"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Jenis Cateter
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arando_dc_jenis_cateter_text"
                                      :options="Config.mr.mDurJenisKateter" label="text"
                                      :clearable="true" :reduce="v=>v.value"></v-select>
                                    </td>

                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_dc_jumlah_urine"
                                        name="arando_dc_jumlah_urine"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Jumlah Urine
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <b-form-input v-model="row.arando_dc_jumlah_urine_text" :formatter="numberOnly" type="text" class="form-control">
                                      </b-form-input>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_dc_warna_urine"
                                        name="arando_dc_warna_urine"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Warna Urine
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <b-form-input v-model="row.arando_dc_warna_urine_text" type="text" class="form-control">
                                      </b-form-input>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_dc_balon"
                                        name="arando_dc_balon"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Balon
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <b-form-input v-model="row.arando_dc_balon_text" type="text" class="form-control">
                                        </b-form-input>
                                        <div class="input-group-append"><span class="input-group-text">cc</span>
                                        </div>
                                      </div>
                                    </td>

                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_dc_kesulitan_pemasangan"
                                        name="arando_dc_kesulitan_pemasangan"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Kesulitan Pemasangan
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <b-form-input v-model="row.arando_dc_kesulitan_pemasangan_text" type="text" class="form-control">
                                      </b-form-input>
                                    </td>

                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_dc_urine_bag"
                                        name="arando_dc_urine_bag"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Urine bag
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <b-form-input v-model="row.arando_dc_urine_bag_text" type="text" class="form-control">
                                      </b-form-input>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td v-else> - </td>
                          </tr>

                          <tr>
                            <td>7 </td>
                            <td>Troniquest</td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_tq_aksi"
                              />
                            </td>

                            <td v-if="row.arando_tq_aksi == 'Y'">
                              <table class="table  table-sm  table-bordered">
                                <tbody>

                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_tq_lengan_kanan"
                                        name="arando_tq_lengan_kanan"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Lengan Kanan
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <label for="">Jam Mulai</label>
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_tq_lengan_kanan_jam_mulai">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                    <td>
                                      <label for="">Jam Selesai</label>
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_tq_lengan_kanan_jam_selesai">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                    <td>
                                      <label>Tekanan</label>
                                      <b-form-textarea v-model="row.arando_tq_lengan_kanan_tekanan" rows="2" class="form-control" placeholder=""
                                        spellcheck="false"></b-form-textarea>
                                    </td>
                                  </tr>
                                  
                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_tq_lengan_kiri"
                                        name="arando_tq_lengan_kiri"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Lengan Kanan
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <label for="">Jam Mulai</label>
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_tq_lengan_kiri_jam_mulai">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                    <td>
                                      <label for="">Jam Selesai</label>
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_tq_lengan_kiri_jam_selesai">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                    <td>
                                      <label>Tekanan</label>
                                      <b-form-textarea v-model="row.arando_tq_lengan_kiri_tekanan" rows="2" class="form-control" placeholder=""
                                        spellcheck="false"></b-form-textarea>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_tq_paha_kanan"
                                        name="arando_tq_paha_kanan"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Paha Kanan
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <label for="">Jam Mulai</label>
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_tq_paha_kanan_jam_mulai">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                    <td>
                                      <label for="">Jam Selesai</label>
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_tq_paha_kanan_jam_selesai">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                    <td>
                                      <label>Tekanan</label>
                                      <b-form-textarea v-model="row.arando_tq_paha_kanan_tekanan" rows="2" class="form-control" placeholder=""
                                        spellcheck="false"></b-form-textarea>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b-form-checkbox
                                        v-model="row.arando_tq_paha_kiri"
                                        name="arando_tq_paha_kiri"
                                        value="Y"
                                        unchecked-value="N"
                                      >
                                        Paha Kiri
                                      </b-form-checkbox>
                                    </td>
                                    <td>
                                      <label for="">Jam Mulai</label>
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_tq_paha_kiri_jam_mulai">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                    <td>
                                      <label for="">Jam Selesai</label>
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arando_tq_paha_kiri_jam_selesai">
                                        </vue-timepicker>
                                      </div>
                                    </td>
                                    <td>
                                      <label>Tekanan</label>
                                      <b-form-textarea v-model="row.arando_tq_paha_kiri_tekanan" rows="2" class="form-control" placeholder=""
                                        spellcheck="false"></b-form-textarea>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td v-else> - </td>
                          </tr>
                          <tr>
                            <td>8 </td>
                            <td>Drain</td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_drain_aksi"
                              />
                            </td>

                            <td v-if="row.arando_drain_aksi == 'Y'">
                              <b-form-checkbox-group
                                class="checkbox-block"
                                v-model="row.arando_drain"
                                :options="Config.mr.mDurDrain"
                                name="arando_drain"
                              ></b-form-checkbox-group>

                              <div style="width: 210px;">
                                <b-form-input v-model="row.arando_drain_text" type="text" class="form-control">
                                </b-form-input>
                              </div>
                            </td>
                            <td v-else> - </td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>Irigasi Luka </td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_irigasi_luka_aksi"
                              />
                            </td>

                            <td v-if="row.arando_irigasi_luka_aksi == 'Y'">
                              <b-form-checkbox-group
                                class="checkbox-block"
                                v-model="row.arando_irigasi"
                                :options="Config.mr.mDurIrigasiLuka"
                                name="arando_irigasi"
                              ></b-form-checkbox-group>

                              <div style="width: 210px;">
                                <b-form-input v-model="row.arando_irigasi_lainnya" type="text" class="form-control">
                                </b-form-input>
                              </div>
                              
                            </td>
                            <td v-else> - </td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>Pemakaian cairan irigasi </td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_cairan_irigasi_aksi"
                              />
                            </td>

                            <td v-if="row.arando_cairan_irigasi_aksi == 'Y'">
                              <b-form-checkbox-group
                                class="checkbox-block"
                                v-model="row.arando_cairan_irigasi_data"
                                :options="Config.mr.mDurPemakaianCairan"
                                name="arando_cairan_irigasi_data"
                              ></b-form-checkbox-group>

                              <div style="width: 210px;">
                                <b-form-input v-model="row.arando_cairan_irigasi_text" type="text" class="form-control">
                                </b-form-input>
                              </div>
                            </td>
                            <td v-else> - </td>
                          </tr>

                          <tr>
                            <td>11</td>
                            <td>Balutan </td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_balutan_aksi"
                              />
                            </td>

                            <td v-if="row.arando_balutan_aksi == 'Y'">
                              <b-form-checkbox-group
                                class="checkbox-block"
                                v-model="row.arando_balutan_data"
                                :options="Config.mr.mDurBalutan"
                                name="arando_balutan_data"
                              ></b-form-checkbox-group>
                            </td>
                            <td v-else> - </td>
                          </tr>

                          <tr>
                            <td>12</td>
                            <td>Specimen untuk Pemeriksaan </td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_specimen_aksi"
                              />
                            </td>

                            <td v-if="row.arando_specimen_aksi == 'Y'">
                              <b-form-checkbox-group
                                class="checkbox-block"
                                v-model="row.arando_specimen_data"
                                :options="Config.mr.mDurSpecimenPemeriksaan"
                                name="arando_specimen_data"
                              ></b-form-checkbox-group>
                              <div style="width: 210px;">
                                <b-form-input v-model="row.arando_specimen_text" type="text" class="form-control">
                                </b-form-input>
                              </div>
                              
                            </td>

                            <td v-else> - </td>
                          </tr>

                          <tr>
                            <td>13</td>
                            <td>Specimen untuk dibawa pasien</td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_specimen_pasien_aksi"
                              />
                            </td>

                            <td v-if="row.arando_specimen_pasien_aksi == 'Y'">
                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  v-model="row.arando_specimen_pasien_total"
                                  name="arando_specimen_pasien_total"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Jumlah total jaringan / cairan
                                </b-form-checkbox>
                                <div v-if="row.arando_specimen_pasien_total == 'Y'" class="ml-2">
                                  <b-form-input v-model="row.arando_specimen_pasien_total_value" type="text" class="form-control form-control-sm" placeholder=""/>
                                </div>
                              </div>

                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  v-model="row.arando_specimen_pasien_jaringan"
                                  name="arando_specimen_pasien_jaringan"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Jenis dari jaringan
                                </b-form-checkbox>
                                <div v-if="row.arando_specimen_pasien_jaringan == 'Y'" class="ml-2">
                                  <b-form-input v-model="row.arando_specimen_pasien_jaringan_value" type="text" class="form-control form-control-sm" placeholder=""/>
                                </div>
                              </div>

                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  v-model="row.arando_specimen_pasien_keterangan"
                                  name="arando_specimen_pasien_keterangan"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Keterangan
                                </b-form-checkbox>
                                <div v-if="row.arando_specimen_pasien_keterangan == 'Y'" class="ml-2">
                                  <b-form-input v-model="row.arando_specimen_pasien_keterangan_value" type="text" class="form-control form-control-sm" placeholder=""/>
                                </div>
                              </div>
                          
                            </td>
                            <td v-else> - </td>
                          </tr>

                          <tr>
                            <td>14</td>
                            <td>Specimen untuk dibuang sebagai limbah/ dihancurkan </td>
                            <td>
                              <b-form-radio-group class="mr-2 custom-block"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arando_specimen_buangan_aksi"
                              />
                            </td>

                            <td v-if="row.arando_specimen_buangan_aksi == 'Y'">
                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  v-model="row.arando_specimen_buangan_total"
                                  name="arando_specimen_buangan_total"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Jumlah total jaringan / cairan
                                </b-form-checkbox>
                                <div v-if="row.arando_specimen_buangan_total == 'Y'" class="ml-2">
                                  <b-form-input v-model="row.arando_specimen_buangan_total_value" type="text" class="form-control form-control-sm" placeholder=""/>
                                </div>
                              </div>

                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  v-model="row.arando_specimen_buangan_jaringan"
                                  name="arando_specimen_buangan_jaringan"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Jenis dari jaringan
                                </b-form-checkbox>
                                <div v-if="row.arando_specimen_buangan_jaringan == 'Y'" class="ml-2">
                                  <b-form-input v-model="row.arando_specimen_buangan_jaringan_value" type="text" class="form-control form-control-sm" placeholder=""/>
                                </div>
                              </div>

                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  v-model="row.arando_specimen_buangan_keterangan"
                                  name="arando_specimen_buangan_keterangan"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Keterangan
                                </b-form-checkbox>
                                <div v-if="row.arando_specimen_buangan_keterangan == 'Y'" class="ml-2">
                                  <b-form-input v-model="row.arando_specimen_buangan_keterangan_value" type="text" class="form-control form-control-sm" placeholder=""/>
                                </div>
                              </div>
                          
                            </td>
                            <td v-else> - </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital Setelah Operasi</h6>
                    </div>
                                        <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Tekanan Darah</label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="row.arando_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                                
                                <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

                                <b-form-input :formatter="number" placeholder="Diastole" v-model="row.arando_ttv_tekanan_darah_max" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                            </div>      

                            <VValidate 
                                name="Tekanan Darah Min" 
                                v-model="row.arando_ttv_tekanan_darah_min" 
                                :rules="toValidate(mrValidation.arando_ttv_tekanan_darah_min)"
                            />
                            <VValidate 
                                name="Tekanan Darah Max" 
                                v-model="row.arando_ttv_tekanan_darah_max" 
                                :rules="toValidate(mrValidation.arando_ttv_tekanan_darah_max)"
                            />
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Ukuran Pupil </label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arando_ttv_ukuran_pupil"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">mm/Ki</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Ukuran Pupil" 
                                v-model="row.arando_ttv_ukuran_pupil" 
                                :rules="toValidate(mrValidation.arando_ttv_ukuran_pupil)"
                            />
                            </div>

                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Nadi</label>
                                <div class="form-row">
                                    <div class="col-md-12">
                                    <div class="input-group">
                                        <b-form-input :formatter="number" v-model="row.arando_ttv_nadi" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                        <div class="input-group-append">
                                            <div style="width: 140px;">
                                            <v-select placeholder="Pilih Label" v-model="row.arando_ttv_label" :options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <VValidate 
                                    name="Nadi" 
                                    v-model="row.arando_ttv_nadi" 
                                    :rules="toValidate(mrValidation.arando_ttv_nadi)"
                                />
                                <VValidate 
                                    name="Label" 
                                    v-model="row.arando_ttv_label" 
                                    :rules="toValidate(mrValidation.arando_ttv_label)"
                                />
                            </div>
                        </div>
                        
                        <div class="col-md-2">
                            <div class="form-group">
                            <label>GDA</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="alphanum" v-model="row.arando_ttv_gula_darah"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Gula Darah" 
                                v-model="row.arando_ttv_gula_darah" 
                                :rules="toValidate(mrValidation.arando_ttv_gula_darah)"
                            />
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Pernafasan</label>
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arando_ttv_pernafasan"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                </div>
        
                                <VValidate 
                                    name="Pernafasan" 
                                    v-model="row.arando_ttv_pernafasan" 
                                    :rules="toValidate(mrValidation.arando_ttv_pernafasan)"
                                />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>SPO2</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arando_ttv_spo2"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">%</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="SPO2" 
                                v-model="row.arando_ttv_spo2" 
                                :rules="toValidate(mrValidation.arando_ttv_spo2)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Suhu</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arando_ttv_suhu"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">C</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                                                       
                            <VValidate 
                                name="Suhu" 
                                v-model="row.arando_ttv_suhu" 
                                :rules="toValidate(mrValidation.arando_ttv_suhu)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Berat Badan</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arando_ttv_weight"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                                                                       
                            <VValidate 
                                name="Berat Badan" 
                                v-model="row.arando_ttv_weight" 
                                :rules="toValidate(mrValidation.arando_ttv_weight)"
                            />
                            </div>
                        </div>

                        
                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Tinggi</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input @input="hitungBMI()"  :formatter="number" v-model="row.arando_ttv_height"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Tinggi Badan" 
                                v-model="row.arando_ttv_height" 
                                :rules="toValidate(mrValidation.arando_ttv_height)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Lingkar Kepala</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arando_ttv_lingkar_kepala"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Lingkar Kepala" 
                                v-model="row.arando_ttv_lingkar_kepala" 
                                :rules="toValidate(mrValidation.arando_ttv_lingkar_kepala)"
                            />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>BMI</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input disabled v-model="row.arando_ttv_bmi"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">m2</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div class="col-md-3" v-if="row.ap_usia <= 15">
                            <div class="form-group">
                            <label>Luas Permukaan Tubuh Anak</label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="row.arando_ttv_luas_permukaan_anak"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
                            </div>
                            </div>
                        </div>

                        </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-2">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Kajian Nyeri</h6>
                    </div>
                    <!-- Skala Nyeri jika pasien berusia lebih dari dengan 6 tahun -->
                    <div class="col-12" v-if="(row.ap_usia > 6 )">
                        <div class="card">
                            <div class="card-header bg-info">
                                <h6 class="card-title font-weight-semibold">Numeric Pain Rating Scale</h6>
                            </div>
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-md-auto">
                                        <label for="perPain">Skala Nyeri<strong class="text-danger">*</strong></label>
                                        <div>
                                            <div v-for="(v,k) in Config.mr.skalaNyeri" :key="k" :class="`pain-scale-radio ${v.class}`">
                                            <input type="radio" v-model="row.arando_skala_nyeri_value" name="perPainScale" 
                                             :id="`perPainScale${v.text}`" :value="v.value" class="psr-input">
                                            <label :for="`perPainScale${v.text}`" class="psr-label">
                                                <span class="psr-label-content">{{v.text}}</span>
                                                <div class="psr-check-container"></div>
                                            </label>
                                            </div>
                                            
                                            <VValidate 
                                                name="Skala Nyeri" 
                                                v-model="row.arando_skala_nyeri_value" 
                                                :rules="{required : 1}"
                                            />
                                            
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="pain-scale no-pain" v-if="row.arando_skala_nyeri_value == 0">
                                            <img :src="assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Tidak Ada Nyeri</strong>
                                        </div>
                                        <div class="pain-scale mild-pain" v-if="row.arando_skala_nyeri_value >= 1 && row.arando_skala_nyeri_value <= 3">
                                            <img :src="assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Sedikit Nyeri</strong>
                                        </div>
                                        <div class="pain-scale moderate-pain" v-if="row.arando_skala_nyeri_value >= 4 && row.arando_skala_nyeri_value <= 5">
                                            <img :src="assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Agak Mengganggu</strong>
                                        </div>
                                        <div class="pain-scale severe-pain" v-if="row.arando_skala_nyeri_value >= 6 && row.arando_skala_nyeri_value <= 7">
                                            <img :src="assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Mengganggu Aktivitas</strong>
                                        </div>
                                        <div class="pain-scale worst-pain" v-if="row.arando_skala_nyeri_value >= 8 && row.arando_skala_nyeri_value <= 9">
                                            <img :src="assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Sangat Mengganggu</strong>
                                        </div>
                                        <div class="pain-scale worst-pain" v-if="row.arando_skala_nyeri_value >= 10">
                                            <img :src="assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Tak Tertahankan</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Skala Nyeri jika pasien berusia kurang dari 6 tahun -->
                    <div class="col-12" v-if="(row.ap_usia <= 6)">
                        <div class="card">
                            <template v-if="(row.ap_usia > 1 )">
                                <div class="card-header bg-info">
                                    <h5 class="card-title font-weight-semibold">FLACC Scale</h5>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">
                                    <label for="kidPainScale">Skala Nyeri</label>
                                    <table class="table table-bordered pain-scale-table">
                                        <thead>
                                        <tr>
                                            <th>Pengkajian</th>
                                            <th>Nilai 0</th>
                                            <th>Nilai 1</th>
                                            <th>Nilai 2</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(v,k) in (row.arando_skala_nyeri_flacc||[])" :key="k">
                                            <th class="table-warning text-center"><img :src="assetLocal(v.img)" style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>{{v.name||"-"}}</th>
                                            <td v-for="(vc,kc) in v.configVal" :key="kc">
                                            <div class="form-check form-check-inline align-middle">
                                            <label class="form-check-label" :for="v.name+k+kc">
                                                <b-form-radio type="radio" :id="v.name+k+kc" :value="vc.value" v-model="row.arando_skala_nyeri_flacc[k]['value']" class="form-check-input-styled" />{{vc.text}}                                
                                            </label>
                                            </div>
                                            <br/>
                                            <VValidate 
                                                :name="v.name" 
                                                v-model="row.arando_skala_nyeri_flacc[k]['value']" 
                                                :rules="{required : 1}"
                                            />
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tfoot>
                                        <tr class="table-info">
                                            <td colspan="4" class="text-info-700">
                                            <span>SKALA: {{hitungSkala}} </span>
                                            
                                            <span v-if="hitungSkala == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                            <span v-else-if="hitungSkala >= 1 && hitungSkala < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                            <span v-else-if="hitungSkala >= 4 && hitungSkala < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                            
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                    </div>
                                </div>
                            </template>

                            <template v-if="(row.ap_usia <= 1)">
                                <div class="card-header bg-info">
                                    <h5 class="card-title font-weight-semibold">Neonatal-Infant Pain Scale (NIPS)</h5>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">
                                    <label for="kidPainScale">Skala Nyeri</label>
                                    <table class="table table-bordered pain-scale-table">
                                        <thead>
                                        <tr>
                                            <th>Pengkajian</th>
                                            <th>Nilai 0</th>
                                            <th>Nilai 1</th>
                                            <th>Nilai 2</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(v,k) in (row.arando_skala_nyeri_nips||[])" :key="k">
                                            <th class="table-warning text-center"><img :src="assetLocal(v.img)" style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>{{v.name||"-"}}</th>
                                            <td v-for="(vc,kc) in v.configVal" :key="kc">
                                            <div class="form-check form-check-inline align-middle">
                                            <label class="form-check-label" :for="v.name+k+kc">
                                                <b-form-radio @input="inputData('skala_nyeri_nips')" type="radio" :value="vc.value" :id="v.name+k+kc" v-model="row.arando_skala_nyeri_nips[k]['value']" class="form-check-input-styled" />{{vc.text}}
                                            </label>
                                            </div>
                                            <br/>
                                            <VValidate 
                                                :name="v.name" 
                                                v-model="row.arando_skala_nyeri_nips[k]['value']" 
                                                :rules="{required : 1}"
                                            />
                                            
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tfoot>
                                        <tr class="table-info">
                                            <td colspan="4" class="text-info-700">
                                            <span>SKALA: {{hitungSkala}} </span>
                                            
                                            <span v-if="hitungSkala < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                            <span v-else-if="hitungSkala >= 3 && hitungSkala < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                    
                                    <span class="text-warning d-none" id="skala_nyeri_nips-typing">User Lain Sedang Mengisi ...</span>                                                                                   
                                    </div>
                                </div>  
                            </template>     
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'

export default {
  extends: GlobalVue,
  data(){
    return {
      rowReg: {},
      menuPage: 'RanapDuranteOperasi',

      patientData: {},
      loading: {
          patientInfo: false,
      },
      noET: [
        {
          value: 2,
          text: 2
        },
        {
          value: 2.5,
          text: 2.5
        },
        {
          value: 3,
          text: 3
        },
        {
          value: 3.5,
          text: 3.5
        },
        {
          value: 4,
          text: 4
        },
        {
          value: 4.5,
          text: 4.5
        },
        {
          value: 5,
          text: 5
        },
        {
          value: 5.5,
          text: 5.5
        },
        {
          value: 6,
          text: 6
        },
        {
          value: 6.5,
          text: 6.5
        },
        {
          value: 7,
          text: 7
        },
        {
          value: 4.5,
          text: 4.5
        },
        {
          value: 7.5,
          text: 7.5
        },
        {
          value: 8,
          text: 8
        },
      ]
    }
  },
  computed: {
    hitungSkala(){
      let value = 0
      if(this.row.ap_usia <= 1){   
        for(let i = 0; i < (this.row.arando_skala_nyeri_nips||[]).length; i++){
            value += this.row.arando_skala_nyeri_nips[i]['value']
        }   
      }else if(this.row.ap_usia > 1 && this.row.ap_usia <= 6){
        for(let i = 0; i < (this.row.arando_skala_nyeri_flacc||[]).length; i++){
          value += this.row.arando_skala_nyeri_flacc[i]['value']
        }   
      }else{
        value += this.row.arando_skala_nyeri_value
      }
      return value
    },  
  },
  components:{ Datepicker,VueTimepicker,PatientInfo },
  mounted() {
    this.apiGet() 

    this.getPatientInfo()
  },
  methods: {
    
    hitungDurante(key){
      this.row.arando_item_data[key]['jumlah'] = parseInt(this.row.arando_item_data[key]['hitungan_pertama']||0)+parseInt(this.row.arando_item_data[key]['tambahan1']||0)+parseInt(this.row.arando_item_data[key]['tambahan2']||0)+parseInt(this.row.arando_item_data[key]['tambahan3']||0)  
      this.row.arando_item_data[key]['hitungan_terakhir'] = parseInt(this.row.arando_item_data[key]['jumlah']||0)+ parseInt(this.row.arando_item_data[key]['hitungan_kedua']||0)

    },

    addItemTambahan(){
      this.row.arando_item_data_lainnya.push({
        "jenis": null,
        "hitungan_pertama": null,
        "tambahan1": null,
        "tambahan2": null,
        "tambahan3": null,
        "jumlah": null,
        "hitungan_kedua": null,
        "hitungan_terakhir": null,
      })
    },

    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.query.regId){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                console.log(res.data)
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },
    
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if(!data.isEdit){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),


    hitungBMI(){
      if(this.row.arando_ttv_weight && this.row.arando_ttv_height){
          this.row.arando_ttv_bmi = this.row.arando_ttv_weight / ((this.row.arando_ttv_height/100)*(this.row.arando_ttv_height/100))
          
          this.row.arando_ttv_bmi = this.row.arando_ttv_bmi.toFixed(2) 
      }else{
          this.row.arando_ttv_bmi = null 
      }
    },
    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          if(this.row.arando_prep_is_desinfektan == 'Y' && !(this.row.arando_prep_desinfektan||[]).length){
            return this.$swal({
                icon: 'error',
                title: 'Desinfektan harus diisi minimal  1 item'
            })
          }
          if(this.row.arando_prep_is_penutup_luka == 'Y' && !(this.row.arando_prep_penutup_luka||[]).length){
            return this.$swal({
                icon: 'error',
                title: 'Penutup luka harus diisi minimal  1 item'
            })
          }
          if(this.row.arando_is_prep_penyeritan == 'Y' && !(this.row.arando_prep_penyeritan||[]).length){
            return this.$swal({
                icon: 'error',
                title: 'Penyeritilan instrument harus diisi minimal  1 item'
            })
          }
          if(this.row.arando_is_prep_klarifikasi == 'Y' && !(this.row.arando_prep_klarifikasi||[]).length){
            return this.$swal({
                icon: 'error',
                title: 'Klasifikasi harus diisi minimal  1 item'
            })
          }

          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapOperasiPerawatBedah'}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    }
  },
  watch:{
    row: {
        handler(v) {
            this.autoSave(v)
        },
        deep: true
    }
  }
}
</script>