<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />

    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <h5 class="card-title font-weight-semibold">Ceklis Keamanan Anestesi</h5>
            </div>
            <div class="card-body">
              <table class="table table-sm">
                <tbody>
                  <tr class="table-info">
                    <th colspan="2"><span class="text-info-800 font-weight-semibold">Listrik</span></th>
                  </tr>
                  <b-form-checkbox-group class="checkbox-block-mb-0 display-c"
                    v-model="row.aranca_listrik"
                    name="aranca_listrik"
                  >
                  <div class="display-c" v-for="(v,k) in listrik" :key="k">
                  <tr>
                    <td colspan="2">
                      <b-form-checkbox :value="v.value">{{v.text}}</b-form-checkbox>
                    </td>
                  </tr>
                  </div>
                  </b-form-checkbox-group>
                </tbody>
                <tbody>
                  <tr class="table-info">
                    <th colspan="2"><span class="text-info-800 font-weight-semibold">Gas Medis</span></th>
                  </tr>
                  <b-form-checkbox-group class="checkbox-block-mb-0 display-c"
                    v-model="row.aranca_gas_medis"
                    name="aranca_gas_medis"
                  >
                  <div class="display-c" v-for="(v,k) in gasMedis" :key="k">
                  <tr>
                    <td colspan="2">
                      <b-form-checkbox :value="v.value">{{v.text}}</b-form-checkbox>
                    </td>
                  </tr>
                  </div>
                  </b-form-checkbox-group>
                </tbody>
                <tbody>
                  <tr class="table-info">
                    <th colspan="2"><span class="text-info-800 font-weight-semibold">Mesin Anestesi</span></th>
                  </tr>
                  <b-form-checkbox-group class="checkbox-block-mb-0 display-c"
                    v-model="row.aranca_mesin_anestesi"
                    name="aranca_mesin_anestesi"
                  >
                  <div class="display-c" v-for="(v,k) in mesinAnestesi" :key="k">
                  <tr>
                    <td colspan="2">
                      <b-form-checkbox :value="v.value">{{v.text}}</b-form-checkbox>
                    </td>
                  </tr>
                  </div>
                  </b-form-checkbox-group>
                </tbody>
                <tbody>
                  <tr class="table-info">
                    <th colspan="2"><span class="text-info-800 font-weight-semibold">Manajemen Jalan Nafas</span></th>
                  </tr>
                  <b-form-checkbox-group class="checkbox-block-mb-0 display-c"
                    v-model="row.aranca_manajemen_jalan_nafas"
                    name="aranca_manajemen_jalan_nafas"
                  >
                  <div class="display-c" v-for="(v,k) in manajemenJalanNafas" :key="k">
                  <tr>
                    <td colspan="2">
                      <b-form-checkbox :value="v.value">{{v.text}}</b-form-checkbox>
                    </td>
                  </tr>
                  </div>
                  </b-form-checkbox-group>
                </tbody>
                <tbody>
                  <tr class="table-info">
                    <th colspan="2"><span class="text-info-800 font-weight-semibold">Pemantauan</span></th>
                  </tr>
                  <b-form-checkbox-group class="checkbox-block-mb-0 display-c"
                    v-model="row.aranca_pemantauan"
                    name="aranca_pemantauan"
                  >
                  <div class="display-c" v-for="(v,k) in pemantauan" :key="k">
                  <tr>
                    <td colspan="2">
                      <b-form-checkbox :value="v.value">{{v.text}}</b-form-checkbox>
                    </td>
                  </tr>
                  </div>
                  </b-form-checkbox-group>
                </tbody>
                <tbody>
                  <tr class="table-info">
                    <th colspan="2"><span class="text-info-800 font-weight-semibold">Lain-lain</span></th>
                  </tr>
                  <b-form-checkbox-group class="checkbox-block-mb-0 display-c"
                    v-model="row.aranca_lainnya"
                    name="aranca_lainnya"
                  >
                  <div class="display-c" v-for="(v,k) in lainnya" :key="k">
                  <tr>
                    <td colspan="2">
                      <b-form-checkbox :value="v.value">{{v.text}}</b-form-checkbox>
                    </td>
                  </tr>
                  </div>
                  </b-form-checkbox-group>
                </tbody>
                <tbody>
                  <tr class="table-info">
                    <th colspan="2"><span class="text-info-800 font-weight-semibold">Obat-obat</span></th>
                  </tr>
                  <b-form-checkbox-group class="checkbox-block-mb-0 display-c"
                    v-model="row.aranca_obat"
                    name="aranca_obat"
                  >
                  <div class="display-c" v-for="(v,k) in obat" :key="k">
                  <tr>
                    <td colspan="2">
                      <b-form-checkbox :value="v.value">{{v.text}}</b-form-checkbox>
                    </td>
                  </tr>
                  </div>
                  </b-form-checkbox-group>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="2" class="p-4"></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="table-info">
                    <td colspan="2">
                      <span class="text-info-800 d-block font-weight-semibold mb-1">Silakan centang pernyataan berikut</span>
                      <div class="display-c">
                        <b-form-checkbox class="custom-control custom-control-inline custom-checkbox"
                          v-model="row.aranca_is_approved"
                          name="aranca_is_approved"
                          value="Y"
                          :unchecked-value="null"
                        > Tindakan ini telah disetujui oleh Dokter Anstesi 
                        <span class="font-weight-bold">
                          {{row.dokter_anestesi||"-"}}
                        </span>
                        </b-form-checkbox>
                        <VValidate 
                          class="d-block"
                          name="Perseutjuan Dokter" 
                          v-model="row.aranca_is_approved" 
                          :rules="{required: 1}"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'


export default {
  extends: GlobalVue,
  data(){
    return {
      rowReg: {},
      menuPage: 'RanapCeklistAnestesi',

      pemantauan: [
          { text: "SpO2 Terhubung", value: "SpO2 Terhubung" },
          { text: "NIBP Terhubung", value: "NIBP Terhubung" }
      ],
      gasMedis: [
          { text: "Selang oksigen, N2O terhubung antara sumber gas dengan mesin anestesi", value: "Selang oksigen, N2O terhubung antara sumber gas dengan mesin anestesi" },
          { text: "Flow O2 dan N2O di mesin anestesi berfungsi, aliran gas keluar dari mesin dapat dirasakan", value: "Flow O2 dan N2O di mesin anestesi berfungsi, aliran gas keluar dari mesin dapat dirasakan" }
      ],
      manajemenJalanNafas: [
          { text: "Sungkup Muka dan oropharyngeal dalam ukuran yang benar", value: "Sungkup Muka dan oropharyngeal dalam ukuran yang benar" },
          { text: "ETT dan LMA dalam ukuran yang benar, tidak bocor", value: "ETT dan LMA dalam ukuran yang benar, tidak bocor" },
          { text: "Spuit untuk mengembangkan cuff", value: "Spuit untuk mengembangkan cuff" },
          { text: "Gagang dan Bilah Laringoscope berfungsi baik dan dengan ukuran yang sesuai", value: "Gagang dan Bilah Laringoscope berfungsi baik dan dengan ukuran yang sesuai" },
          { text: "Stilet / Mandrin", value: "Stilet / Mandrin" }
      ],
      listrik: [
          { text: "Mesin Anestesi dan monitor tanda vital terhubung dengan sumber listrik menyala", value: "Mesin Anestesi dan monitor tanda vital terhubung dengan sumber listrik menyala" }
      ],
      mesinAnestesi: [
          { text: "Power On", value: "Power On" },
          { text: "Volati terasa dan absorber CO2 dalam kondisi baik", value: "Volati terasa dan absorber CO2 dalam kondisi baik" },
          { text: "Tidak ada kebocoran sirkuit nafas", value: "Tidak ada kebocoran sirkuit nafas" }
      ],
      lainnya: [
        { text: "Stetoscope Tersedia", value: "Stetoscope Tersedia" },
        { text: "Nasal kanul oksigen terhubung dengan sumber gas", value: "Nasal kanul oksigen terhubung dengan sumber gas" },
        { text: "Suction, selang suction, canul suction berfungsi baik dan dalam ukuran yang benar", value: "Suction, selang suction, canul suction berfungsi baik dan dalam ukuran yang benar" },
        { text: "Intravenous Line", value: "Intravenous Line" },
        { text: "Plester untuk fiksasi", value: "Plester untuk fiksasi" },
        { text: "Spuit berbagai ukuran", value: "Spuit berbagai ukuran" }
      ],
      obat: [
        { text: "Epinefrin", value: "Epinefrin" },
        { text: "Opiat / Opioid", value: "Opiat / Opioid" },
        { text: "Otoprin", value: "Otoprin" },
        { text: "Cairan Intravenous", value: "Cairan Intravenous" },
        { text: "Sedatif (Midazolam, Propofol, Ketamin)", value: "Sedatif (Midazolam, Propofol, Ketamin)" }
      ],
      patientData: {},
      loading: {
          patientInfo: false,
      },
    }
  },
  mounted() {
    this.apiGet() 
    this.getPatientInfo()
  },
  components:{
    PatientInfo
  },
  computed:{
    isKosong(){
      return  (!(this.row.aranca_listrik||[]).length && 
      !(this.row.aranca_gas_medis||[]).length && 
      !(this.row.aranca_mesin_anestesi||[]).length &&
      !(this.row.aranca_manajemen_jalan_nafas||[]).length &&
      !(this.row.aranca_pemantauan||[]).length &&
      !(this.row.aranca_lainnya||[]).length &&
      !(this.row.aranca_obat||[]).length)
    }
  },
  methods: {
    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.query.regId){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                console.log(res.data)
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },
    
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if(!data.isEdit){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),

    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {

          if(this.isKosong){
            return this.$swal({
              icon: 'error',
              title: 'Ceklis Keamanan Anestesi Belum Diisi'
            })
          }

          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapOperasiDokterAnestesi'}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    }
  },
  watch:{
    row: {
        handler(v) {
            this.autoSave(v)
        },
        deep: true
    }
  }
}
</script>