<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />

    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <h5 class="card-title font-weight-semibold">Form Catatan Ruang Pemulihan</h5>
            </div>
          
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="wrap_width_table">
                    <div class="table-responsive">
                      <table class="table  table-sm  table-bordered">
                        <tbody>
                          <tr>
                            <td style="width:50%;">Diagnosa Masuk </td>
                            <td style="width:50%;">
                            <p><b-form-textarea v-model="row.arancrp_diagnosa_masuk" class="form-control" rows="2"></b-form-textarea></p>
                            <VValidate 
                              name="Diagnosa Masuk" 
                              v-model="row.arancrp_diagnosa_masuk" 
                              :rules="toValidate(mrValidation.arancrp_diagnosa_masuk)"
                            />
                            </td>
                          </tr>
                          <tr>
                            <td>Tindakan medis yang sudah dilakukan</td>
                            <td>
                            <p><b-form-textarea v-model="row.arancrp_tindakan_medis" class="form-control" rows="2"></b-form-textarea></p>
                            <VValidate 
                              name="Tindakan medis yang sudah dilakukan" 
                              v-model="row.arancrp_tindakan_medis" 
                              :rules="toValidate(mrValidation.arancrp_tindakan_medis)"
                            />
                            </td>
                          </tr>
                          <tr>
                            <td class="align-top">
                              <table class="table  table-sm  table-bordered">
                                <tbody>
                                  <tr>
                                    <td>Masuk RR Jam:</td>
                                    <td>
                                      <div class="input-group bootstrap-timepicker timepicker">
                                        <span class="input-group-prepend input-group-addon">
                                          <span class="input-group-text"><i class="icon-alarm"></i></span>
                                        </span>
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arancrp_masuk">
                                        </vue-timepicker>
                                      </div>
                                      <VValidate 
                                        name="Masuk RR Jam" 
                                        v-model="row.arancrp_masuk" 
                                        :rules="toValidate(mrValidation.arancrp_masuk)"
                                      />
                                      
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Jalan Nafas</td>
                                    <td>
                                      <b-form-radio-group
                                        class="custom-block"
                                        :options="mCRPJalanNapas"
                                        v-model="row.arancrp_jalan_nafas"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Pernafasan</td>
                                    <td>
                                      <b-form-radio-group
                                        class="custom-block"                                        
                                        :options="mCRPPernafasan"
                                        v-model="row.arancrp_pernafasan"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Bila Spontan</td>
                                    <td>
                                      <b-form-radio-group
                                        class="custom-block"
                                        :options="mCRPBilaSpontan"
                                        v-model="row.arancrp_bila_spontan"
                                      />
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Kesadaran</td>
                                    <td>
                                      <b-form-radio-group
                                        class="custom-block"
                                        :options="mCRPKesadaran"
                                        v-model="row.arancrp_kesadaran"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <!--
                            <td class="align-top">
                              <div class="wrap_line wl_compact">
                                <h3>Aldrette Score</h3>
                                <div class="mb-2 form-row">
                                  <label class="col-md-3" for="">Warna Kulit</label>
                                  <div class="col-md-9">
                                    <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald1_warna_kulit"
                                      :options="Config.mr.mAldWarnaKulit" label="text"
                                    :clearable="true" :reduce="v=>v.value">
                                      <template slot="selected-option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                      <template slot="option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>

                                <div class="mb-2 form-row">
                                  <label class="col-md-3" for="">Aktifitas Motorik</label>
                                  <div class="col-md-9">
                                    <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald1_aktivitas_motorik"
                                      :options="Config.mr.mAldAktivitas" label="text"
                                    :clearable="true" :reduce="v=>v.value">
                                      <template slot="selected-option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                      <template slot="option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>

                                <div class="mb-2 form-row">
                                  <label class="col-md-3" for="">Pernafasan</label>
                                  <div class="col-md-9">
                                    <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald1_aktivitas_pernafasan"
                                      :options="Config.mr.mAldPernafasan" label="text"
                                    :clearable="true" :reduce="v=>v.value">
                                      <template slot="selected-option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                      <template slot="option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>

                                <div class="mb-2 form-row">
                                  <label class="col-md-3" for="">Tekanan Darah</label>
                                  <div class="col-md-9">
                                    <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald1_aktivitas_tekanan_darah"
                                      :options="Config.mr.mAldTekananDarah" label="text"
                                    :clearable="true" :reduce="v=>v.value">
                                      <template slot="selected-option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                      <template slot="option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>

                                <div class="mb-2 form-row">
                                  <label class="col-md-3" for="">Kesadaran</label>
                                  <div class="col-md-9">
                                    <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald1_aktivitas_kesadaran"
                                      :options="Config.mr.mAldKesadaran" label="text"
                                    :clearable="true" :reduce="v=>v.value">
                                      <template slot="selected-option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                      <template slot="option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>

                                <div class="mb-2 form-row">
                                  <label class="col-md-3" for="">Mual Muntah</label>
                                  <div class="col-md-9">
                                    <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald1_aktivitas_mual_muntah"
                                      :options="Config.mr.mAldMualMuntah" label="text"
                                    :clearable="true" :reduce="v=>v.value">
                                      <template slot="selected-option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                      <template slot="option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>

                                <div class="mb-2 form-row">
                                  <label class="col-md-3" for="">Pendarahan</label>
                                  <div class="col-md-9">
                                    <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald1_aktivitas_pendarahan"
                                      :options="Config.mr.mAldPendarahan" label="text"
                                    :clearable="true" :reduce="v=>v.value">
                                      <template slot="selected-option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                      <template slot="option" slot-scope="option">
                                        <span>({{option.value}}) {{ option.text }}</span>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>

                                <hr class="my-2">

                                <div class="alert alert-info mb-0" v-if="row.arancrp_ald1_total >= 12">
                                  Total: <span class="font-weight-semibold text-info-800">{{row.arancrp_ald1_total||0}}</span> - pasien pindah ruangan
                                </div>
                                
                                <div class="alert alert-danger mb-0"  v-else-if="row.arancrp_ald1_total < 8">
                                  Total: <span class="font-weight-semibold text-danger-800">{{row.arancrp_ald1_total||0}}</span> - pasien pindah ke ICU / Ruang Perawatan Intensif
                                </div>
                                
                                <div class="alert alert-success mb-0" v-else>
                                  Total: <span class="font-weight-semibold text-success-800">{{row.arancrp_ald1_total||0}}</span>
                                </div>

                              </div>
                            </td>
                            -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-6 mt-2">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <div class="d-flex align-items-center justify-content-between">
                        <h6 class="card-title font-weight-semibold">Pemantauan di ruang pulih sadar</h6>
                        <a @click="doOpenPemantauan(null)" href="javascript:;" data-toggle="modal" class="btn btn-sm btn-labeled btn-labeled-left btn-success">
                          <b><i class="icon-plus2"></i></b>
                          <span>Tambah Pantauan</span>
                        </a>
                      </div>
                    </div>
                    <div class="card-body p-3">
                      <div class="alert alert-info">
                        <div class="det-check-item">
                          <i class="icon-info22 mr-2"></i>
                          <span>Pengisian data pemantauan dilakukan setiap 5 - 15 menit</span>
                        </div>
                      </div>
                      <div class="chart-container mb-3">
                        <label for="">Tekanan Darah</label>
                        <div class="chart-container">
                          <LineChart :chartData="resumeData"
                            :chartOptions="chartOptions"
                          />
                        </div>
                      </div>
                      <div>
                        <label for="">Pemantauan</label>
                        <table class="table table-bordered table-sm">
                          <thead>
                            <tr>
                              <th width="40">Waktu</th>
                              <th>Nadi</th>
                              <th>Cairan Masuk</th>
                              <th>Cairan Keluar</th>
                              <th>SpO<sub>2</sub></th>
                              <th>Aksi</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v,k) in (dataRuangPulih||[])" :key="k+'pemantauan'">
                              <td>{{v.aranpp_tanggal | moment("DD MMMM YYYY")}} {{v.aranpp_waktu||"-"}}</td>
                              <td>{{v.aranpp_nadi||"-"}}x/mnt</td>
                              <td>{{v.aranpp_cairan_masuk||"-"}}cc</td>
                              <td>{{v.aranpp_cairan_keluar||"-"}}cc</td>
                              <td>{{v.aranpp_spo2||"-"}}%</td>
                              <td>
                                <a href="javascript:;" @click="doOpenPemantauan(v.aranpp_id,v)" class="btn btn-icon rounded-round btn-sm alpha-info border-info" v-b-tooltip.hover title="Ubah Data">
                                  <i class="icon-pencil"></i>
                                </a>
                                
                                <a href="javascript:;" @click="deletePemantauan(v)" class="mt-1 btn btn-icon rounded-round btn-sm alpha-info border-info" v-b-tooltip.hover title="Hapus Data">
                                  <i class="icon-bin"></i>
                                </a>
                              </td>
                            </tr>
                            <tr v-if="!(dataRuangPulih||[]).length">
                              <td colspan="6">Tidak ada Data Pemantauan</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 mt-2">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Intruksi Pasca Operasi</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Posisi</label>
                            <div>
                              <b-form-radio-group
                                class="custom-block"
                                :options="Config.mr.mCRPPosisi"
                                v-model="row.arancrp_posisi_operasi"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="form-group">
                            <label>Beri O2</label>
                            <div class="input-group">
                              <b-form-input :formatter="number"
                              v-model="row.arancrp_beri_oksigen" type="text" name="name"
                              class="form-control" placeholder="Beri O2" />
                              <div class="input-group-append"><span class="input-group-text">lt/m</span></div>
                              <div class="input-group-append">
                                <div style="width: 150px;">
                                  <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_beri_oksigen_text"
                                    :options="Config.mr.mCRPBeriO2" label="text"
                                  :clearable="true" :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                      <span v-b-tooltip.hover :title="option.text">({{option.value}}) {{ option.text.limitChar(5) }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                      <span v-b-tooltip.hover :title="option.text">({{option.value}}) {{ option.text.limitChar(5) }}</span>
                                    </template>
                                  </v-select>
                                </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Bila tensi turun di bawah</label>
                            <div class="form-row">
                              <div class="col-md-12">
                                <div class="input-group">
                                  <b-form-input :formatter="number"
                                  v-model="row.arancrp_tensi_turun" type="text" name="name"
                                  class="form-control" />
                                  <div class="input-group-append"><span class="input-group-text">mmHg</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Berikan</label>
                            <b-form-input
                            v-model="row.arancrp_berikan" type="text" name="name"
                            class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Bila mual/muntah, beri</label>
                            <b-form-input
                            v-model="row.arancrp_bila_mual" type="text" name="name"
                            class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Bila kesakitan, beri</label>
                            <b-form-input
                            v-model="row.arancrp_bila_kesakitan" type="text" name="name"
                            class="form-control" />
                          </div>
                        </div>
                      </div>
                      <div class="wrap_line wl_compact">
                        <h3>Aldrette Score</h3>
                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="">Warna Kulit</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald2_warna_kulit"
                              :options="Config.mr.mAldWarnaKulit" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="">Aktifitas Motorik</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald2_aktivitas_motorik"
                              :options="Config.mr.mAldAktivitas" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="">Pernafasan</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald2_aktivitas_pernafasan"
                              :options="Config.mr.mAldPernafasan" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="">Tekanan Darah</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald2_aktivitas_tekanan_darah"
                              :options="Config.mr.mAldTekananDarah" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="">Kesadaran</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald2_aktivitas_kesadaran"
                              :options="Config.mr.mAldKesadaran" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="">Mual Muntah</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald2_aktivitas_mual_muntah"
                              :options="Config.mr.mAldMualMuntah" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="">Pendarahan</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_ald2_aktivitas_pendarahan"
                              :options="Config.mr.mAldPendarahan" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <hr class="my-2">

                        <div class="alert alert-info mb-0" v-if="row.arancrp_ald2_total >= 12">
                          Total: <span class="font-weight-semibold text-info-800">{{row.arancrp_ald2_total||0}}</span> - pasien pindah ruangan
                        </div>
                        
                        <div class="alert alert-danger mb-0"  v-else-if="row.arancrp_ald2_total < 8">
                          Total: <span class="font-weight-semibold text-danger-800">{{row.arancrp_ald2_total||0}}</span> - pasien pindah ke ICU / Ruang Perawatan Intensif
                        </div>
                        
                        <div class="alert alert-success mb-0" v-else>
                          Total: <span class="font-weight-semibold text-success-800">{{row.arancrp_ald2_total||0}}</span>
                        </div>
                      </div>
                      
                      <div class="wrap_line wl_compact">
                        <h3>Bromage Score</h3>
                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="">Kriteria</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_brs_kriteria"
                              :options="Config.mr.mBromageScore" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>
                        <hr class="my-2">

                        <div class="alert alert-info mb-0" v-if="row.arancrp_brs_kriteria <= 2">
                          Total: <span class="font-weight-semibold text-info-800">{{row.arancrp_brs_kriteria}}</span> - pasien boleh pindah ruangan
                        </div>
                        <div class="alert alert-danger mb-0" v-else>
                          Total: <span class="font-weight-semibold text-danger-800">{{row.arancrp_brs_kriteria}}</span> - pasien belum boleh pindah ruangan
                        </div>

                      </div>
                      <div class="wrap_line wl_compact">
                        <h3>Steward Score</h3>
                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="stewKesadaran">Kesadaran</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_sts_kesadaran"
                              :options="Config.mr.mStwKesadaranScore" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>
                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="stewPernafasan">Pernafasan</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_sts_pernafasan"
                              :options="Config.mr.mStwPernafasanScore" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>
                        <div class="mb-2 form-row">
                          <label class="col-md-3" for="stewMotorik">Motorik</label>
                          <div class="col-md-9">
                            <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_sts_motorik"
                              :options="Config.mr.mStwMotorikScore" label="text"
                            :clearable="true" :reduce="v=>v.value">
                              <template slot="selected-option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                                <span>({{option.value}}) {{ option.text }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <hr class="my-2">
                        <div class="alert alert-info mb-0" v-if="row.arancrp_sts_total >= 5">
                          Total: <span class="font-weight-semibold text-info-800">{{row.arancrp_sts_total||0}}</span> - pasien boleh pindah ke ruangan
                        </div>
                        <div class="alert alert-danger mb-0" v-else>
                          Total: <span class="font-weight-semibold text-danger-800">{{row.arancrp_sts_total||0}}</span> - pasien belum boleh pindah ke ruangan
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6 mt-2">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Keluar RR</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Tanggal Keluar<small class="txt_mandatory">*</small></label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><i class="icon-calendar22"></i></span>
                              </div>
                              <datepicker :clear-button="true" input-class="form-control transparent h-38" 
                                  calendar-class="my-datepicker"
                                  v-model="row.arancrp_rr_tanggal">
                              </datepicker>
                            </div>
                            
                            <VValidate 
                              name="Tanggal Keluar" 
                              v-model="row.arancrp_rr_tanggal" 
                              :rules="toValidate(mrValidation.arancrp_rr_tanggal)"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Jam Keluar<small class="txt_mandatory">*</small></label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><i class="icon-alarm"></i></span>
                              </div>
                              <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arancrp_rr_jam">
                              </vue-timepicker>
                            </div>
                            <VValidate 
                              name="Jam Keluar" 
                              v-model="row.arancrp_rr_jam" 
                              :rules="toValidate(mrValidation.arancrp_rr_jam)"
                            />
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="form-group">
                            <label>Tekanan Darah<small class="txt_mandatory">*</small></label>
                            <div class="input-group">
                              <b-form-input :formatter="number"
                              v-model="row.arancrp_rr_tekanan_darah_min" type="text" name="name"
                              class="form-control" placeholder="Systole" />
                              <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span>
                              </div>
                              <b-form-input :formatter="number"
                              v-model="row.arancrp_rr_tekanan_darah_max" type="text" name="name"
                              class="form-control" placeholder="Diastole" />
                              <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                            </div>

                            <VValidate 
                              name="Tekanan Darah Min" 
                              v-model="row.arancrp_rr_tekanan_darah_min" 
                              :rules="toValidate(mrValidation.arancrp_rr_tekanan_darah_min)"
                            />
                            <VValidate 
                              name="Tekanan Darah Max" 
                              v-model="row.arancrp_rr_tekanan_darah_max" 
                              :rules="toValidate(mrValidation.arancrp_rr_tekanan_darah_max)"
                            />
                          </div>
                        </div>

                        <div class="col-md-8">
                          <div class="form-group">
                            <label>Nadi<small class="txt_mandatory">*</small></label>
                            <div class="input-group">
                              <b-form-input :formatter="number"
                              v-model="row.arancrp_rr_nadi" type="text" name="name"
                              class="form-control" placeholder="Nadi" />
                              <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                              <div class="input-group-append">
                                <div style="width: 150px;">
                                  <v-select placeholder="Pilih Label" v-model="row.arancrp_rr_label"
                                  :options="Config.mr.StatusRegular" label="text"
                                  :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>
                              </div>
                            </div>
                            <VValidate 
                              name="Nadi" 
                              v-model="row.arancrp_rr_nadi" 
                              :rules="toValidate(mrValidation.arancrp_rr_nadi)"
                            />
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Pernafasan<small class="txt_mandatory">*</small></label>
                            <div class="input-group">
                              <b-form-input :formatter="number"
                              v-model="row.arancrp_rr_pernafasan" type="text" name="name"
                              class="form-control" placeholder="Pernafasan" />
                              <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                            </div>
                            
                            <VValidate 
                              name="Pernafasan" 
                              v-model="row.arancrp_rr_pernafasan" 
                              :rules="toValidate(mrValidation.arancrp_rr_pernafasan)"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label>SPO2<small class="txt_mandatory">*</small></label>
                            <div class="form-row">
                              <div class="col-md-12">
                                <div class="input-group">
                                  <b-form-input :formatter="number"
                                  v-model="row.arancrp_rr_spo2" type="text" name="name"
                                  class="form-control" placeholder="Pernafasan" />
                                  <div class="input-group-append"><span class="input-group-text">%</span>
                                  </div>
                                </div>
                              </div>
                              <VValidate 
                                name="SPO2" 
                                v-model="row.arancrp_rr_spo2" 
                                :rules="toValidate(mrValidation.arancrp_rr_spo2)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6 mt-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Pemberian Terapi</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="row">
                            <div class="col-md-12">
                              <b-form-textarea v-model="row.arancrp_pemberian_terapi" rows="2" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-2">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Pindah Ruang</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="row">
                            <div class="col-md-8">
                              <div class="form-group">
                                <label>Pindah Ke Ruang<small class="txt_mandatory">*</small></label>
                                <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arancrp_pindah_keruang"
                                  :options="mRanapBangsal" label="text"
                                  :clearable="true" :reduce="v=>v.value">
                                </v-select>
                                <VValidate 
                                  name="Pindah Ke Ruang" 
                                  v-model="row.arancrp_pindah_keruang" 
                                  :rules="toValidate(mrValidation.arancrp_pindah_keruang)"
                                />
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Ruang<small class="txt_mandatory">*</small></label>
                                <div>
                                  <b-form-radio-group
                                    class="custom-block"
                                    :options="Config.mr.mRanapRuangTo"
                                    v-model="row.arancrp_ruang"
                                  />
                                  <div v-if="row.arancrp_ruang == 'LN'">
                                    <b-form-input 
                                    v-model="row.arancrp_ruang_text" type="text" name="name"
                                    class="form-control" placeholder="Ruangan Lainnya" />
                                    <VValidate 
                                      name="Ruangan Lainnya" 
                                      v-model="row.arancrp_ruang_text" 
                                      :rules="toValidate(mrValidation.arancrp_ruang_text)"
                                    />
                                  </div>    
                                  <VValidate 
                                    name="Ruangan" 
                                    v-model="row.arancrp_ruang" 
                                    :rules="toValidate(mrValidation.arancrp_ruang)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-2">
                  <table class="table table-bordered table-sm">
                    <tr>
                      <td width="24%" class="align-top">
                        <div class="form-group">
                          <label for="">Tanggal <strong class="text-danger">*</strong></label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="icon-calendar22"></i></span>
                            </div>
                            <datepicker :clear-button="true" input-class="form-control transparent" 
                                calendar-class="my-datepicker"
                                v-model="row.arancrp_tanggal_pengawasan">
                            </datepicker>
                          </div>
                          <VValidate 
                            name="Tanggal Pengawasan" 
                            v-model="row.arancrp_tanggal_pengawasan" 
                            :rules="toValidate(mrValidation.arancrp_tanggal_pengawasan)"
                          />
                        </div>
                        <div>
                          <label for="">Jam <strong class="text-danger">*</strong></label>
                          
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arancrp_jam_pengawasan">
                            </vue-timepicker>
                          </div>
                          <VValidate 
                            name="Jam Pengawasan" 
                            v-model="row.arancrp_jam_pengawasan" 
                            :rules="toValidate(mrValidation.arancrp_jam_pengawasan)"
                          />

                        </div>
                      </td>
                      <td width="38%" class="align-top">
                        <div>
                          <label for="">TTD & Nama Terang Dokter Anestesi<strong class="text-danger">*</strong></label>
                          <b-form-input v-model="row.arancrp_nama_dokter_anestesi" type="text" class="form-control"/>
                          <VValidate 
                              name="Dokter Anestesi" 
                              v-model="row.arancrp_nama_dokter_anestesi" 
                              :rules="{required:1, min:3, max:128}"
                          />
                          <div class="signing-element mt-1">
                            <VueSignaturePad
                                class="sign-container"
                                width="100%"
                                height="160px"
                                ref="ttdDokterAnestesi"
                            />
                            <div class="mt-2">
                            <button type="button" @click="undoTTD('ttdDokterAnestesi','arancrp_ttd_dokter_anestesi')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                <b><i class="icon-cross3"></i></b>
                                <span>Clear</span>
                            </button>
                            
                            <button type="button" @click="output('ttdDokterAnestesi','arancrp_ttd_dokter_anestesi')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                <b><i class="icon-checkmark2"></i></b>
                            <span>Simpan</span>
                            </button>
                            </div>
                            
                            <VValidate name="Tanda Tangan Dokter Anestesi" v-model="row.arancrp_ttd_dokter_anestesi"
                            :rules="{required:1}" />
                          </div>
                          
                        </div>
                      </td>
                      <td width="38%" class="align-top">
                        <div>
                          <label for="">TTD & Nama Terang Perawat Anestesi<strong class="text-danger">*</strong></label>
                          <b-form-input v-model="row.arancrp_nama_asisten_anestesi" type="text" class="form-control"/>
                          <VValidate 
                              name="Perawat Anestesi" 
                              v-model="row.arancrp_nama_asisten_anestesi" 
                              :rules="{required:1, min:3, max:128}"
                          />
                          <div class="signing-element mt-1">
                            <VueSignaturePad
                                class="sign-container"
                                width="100%"
                                height="160px"
                                ref="ttdPerawatAnestesi"
                            />
                            <div class="mt-2">
                            <button type="button" @click="undoTTD('ttdPerawatAnestesi','arancrp_ttd_asisten_anestesi')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                <b><i class="icon-cross3"></i></b>
                                <span>Clear</span>
                            </button>
                            
                            <button type="button" @click="output('ttdPerawatAnestesi','arancrp_ttd_asisten_anestesi')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                <b><i class="icon-checkmark2"></i></b>
                            <span>Simpan</span>
                            </button>
                            </div>
                            
                            <VValidate name="Tanda Tangan Perawat Anestesi" v-model="row.arancrp_ttd_asisten_anestesi"
                            :rules="{required:1}" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>

    <validation-observer ref="VFormPemantauan">
    <b-modal v-model="openPemantauan" @ok.prevent="doSubmitRuangPulih" :title="'Form Pemantauan Ruang Pulih'" size="md" ok-title="Simpan">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Tanggal <strong class="text-danger">*</strong></label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span></div>
              <datepicker :clear-button="true" input-class="form-control transparent h-38" 
                  calendar-class="my-datepicker"
                  v-model="rowEditPemantauan.aranpp_tanggal">
              </datepicker>
            </div>
            
            <VValidate 
              name="Tanggal" 
              v-model="rowEditPemantauan.aranpp_tanggal" 
              :rules="toValidate(mrValidationPemantauan.aranpp_tanggal)"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Waktu <strong class="text-danger">*</strong></label>
            <div class="input-group">
              <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="rowEditPemantauan.aranpp_waktu">
              </vue-timepicker>
              <div class="input-group-append"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
            </div>
            <VValidate 
                name="Waktu" 
                v-model="rowEditPemantauan.aranpp_waktu" 
                :rules="toValidate(mrValidationPemantauan.aranpp_waktu)"
              />
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label>Tekanan Darah<small class="txt_mandatory">*</small></label>
            <div class="input-group">
              <b-form-input :formatter="number"
              v-model="rowEditPemantauan.aranpp_tekanan_darah_min" type="text" name="name"
              class="form-control" placeholder="Systole" />
              <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span>
              </div>
              <b-form-input :formatter="number"
              v-model="rowEditPemantauan.aranpp_tekanan_darah_max" type="text" name="name"
              class="form-control" placeholder="Diastole" />
              <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
            </div>
            
            <VValidate 
              name="Tekanan Darah Min" 
              v-model="rowEditPemantauan.aranpp_tekanan_darah_min" 
              :rules="toValidate(mrValidationPemantauan.aranpp_tekanan_darah_min)"
            />
            <VValidate 
              name="Tekanan Darah Max" 
              v-model="rowEditPemantauan.aranpp_tekanan_darah_max" 
              :rules="toValidate(mrValidationPemantauan.aranpp_tekanan_darah_max)"
            />
          </div>
        </div>
        <div class="col-md-7">
          <div class="form-group">
            <label>Nadi<small class="txt_mandatory">*</small></label>
            <div class="input-group">
              <b-form-input :formatter="number"
              v-model="rowEditPemantauan.aranpp_nadi" type="text" name="name"
              class="form-control" placeholder="Nadi" />
              <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
              <div class="input-group-append">
                <div style="width: 150px;">
                  <v-select placeholder="Pilih Label" v-model="rowEditPemantauan.aranpp_label"
                  :options="Config.mr.StatusRegular" label="text"
                  :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
              </div>
            </div>
            <VValidate 
              name="Nadi" 
              v-model="rowEditPemantauan.aranpp_nadi" 
              :rules="toValidate(mrValidationPemantauan.aranpp_nadi)"
            />
            <VValidate 
              name="Label" 
              v-model="rowEditPemantauan.aranpp_label" 
              :rules="toValidate(mrValidationPemantauan.aranpp_label)"
            />
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group">
            <label>SPO2<small class="txt_mandatory">*</small></label>
            <div class="form-row">
              <div class="col-md-12">
                <div class="input-group">
                  <b-form-input :formatter="number"
                  v-model="rowEditPemantauan.aranpp_spo2" type="text" name="name"
                  class="form-control" placeholder="Nadi" />
                  <div class="input-group-append"><span class="input-group-text">%</span>
                  </div>
                </div>

                <VValidate 
                  name="SPO2" 
                  v-model="rowEditPemantauan.aranpp_spo2" 
                  :rules="toValidate(mrValidationPemantauan.aranpp_spo2)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-grup">
            <label for="">Cairan Masuk <strong class="text-danger">*</strong></label>
            <div class="input-group">
              <b-form-input :formatter="number"
                v-model="rowEditPemantauan.aranpp_cairan_masuk" type="text" name="name"
                class="form-control" placeholder="Cairan Masuk" />
              <div class="input-group-append"><span class="input-group-text">cc</span></div>
            </div>
            <VValidate 
              name="Cairan Masuk" 
              v-model="rowEditPemantauan.aranpp_cairan_masuk" 
              :rules="toValidate(mrValidationPemantauan.aranpp_cairan_masuk)"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-grup">
            <label for="">Cairan Keluar <strong class="text-danger">*</strong></label>
            <div class="input-group">
              <b-form-input :formatter="number"
                v-model="rowEditPemantauan.aranpp_cairan_keluar" type="text" name="name"
                class="form-control" placeholder="Cairan Keluar" />
              <div class="input-group-append"><span class="input-group-text">cc</span></div>
            </div>
            <VValidate 
              name="Cairan Keluar" 
              v-model="rowEditPemantauan.aranpp_cairan_keluar" 
              :rules="toValidate(mrValidationPemantauan.aranpp_cairan_keluar)"
            />
          </div>
        </div>
      </div>
    </b-modal>
    </validation-observer>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import LineChart from '@/components/chart/LineChart'
const moment = require('moment')
import PatientInfo from '@/components/Ranap/PatientInfo.vue'


export default {
  extends: GlobalVue,
  components:{ Datepicker,VueTimepicker,LineChart,PatientInfo },
  computed: {            
    ald1Total(){
      return (this.row.arancrp_ald1_warna_kulit||0) + 
      (this.row.arancrp_ald1_aktivitas_motorik||0) + 
      (this.row.arancrp_ald1_aktivitas_pernafasan||0) +
      (this.row.arancrp_ald1_aktivitas_tekanan_darah||0) +
      (this.row.arancrp_ald1_aktivitas_kesadaran||0) +
      (this.row.arancrp_ald1_aktivitas_mual_muntah||0) +
      (this.row.arancrp_ald1_aktivitas_pendarahan||0)
    },
    
    ald2Total(){
      return (this.row.arancrp_ald2_warna_kulit||0) + 
      (this.row.arancrp_ald2_aktivitas_motorik||0) + 
      (this.row.arancrp_ald2_aktivitas_pernafasan||0) +
      (this.row.arancrp_ald2_aktivitas_tekanan_darah||0) +
      (this.row.arancrp_ald2_aktivitas_kesadaran||0) +
      (this.row.arancrp_ald2_aktivitas_mual_muntah||0) +
      (this.row.arancrp_ald2_aktivitas_pendarahan||0)
    },
        
    stsTotal(){
      return (this.row.arancrp_sts_kesadaran||0) + 
      (this.row.arancrp_sts_pernafasan||0) + 
      (this.row.arancrp_sts_motorik||0)
    },
    
    getLabels(){
      let labels = []
      for(let i = 0; i < (this.dataRuangPulih||[]).length; i++){
        let dates = this.dataRuangPulih[i]['aranpp_waktu']+' '+moment(this.dataRuangPulih[i]['aranpp_tanggal']).format('DD MMM YYYY')
        labels.push(dates)
      }
      return labels
    },
    getSystole(){
      let data = []
      for(let i = 0; i < (this.dataRuangPulih||[]).length; i++){
        data.push(this.dataRuangPulih[i].aranpp_tekanan_darah_min)
      }
      return data
    },
    getDiastole(){
      let data = []
      for(let i = 0; i < (this.dataRuangPulih||[]).length; i++){
        data.push(this.dataRuangPulih[i].aranpp_tekanan_darah_max)
      }
      return data
    },
    resumeData() {
      return {
        "labels": this.getLabels,
        "datasets": [
          {
              "label": "Sistolik",
              "data": this.getSystole,
              "backgroundColor": "#D7241F"
          },
          {
              "label": "Diastolik",
              "data": this.getDiastole,
              "backgroundColor": "#264654"
          }
        ]
      }
    },
  },

  data(){
    return {
      rowReg: {},
      menuPage: 'RanapCatatanRuangPulih',
      mCRPJalanNapas: [
        { value: "Bersih dan Lapang", text: "Bersih dan Lapang" }
      ],
      mCRPPernafasan: [
        { value: "Spontan", text: "Spontan" },
        { value: "Dibantu", text: "Dibantu" }
      ],
      mCRPBilaSpontan: [
        { value: "Adekuat", text: "Adekuat" },
        { value: "Penyumbatan", text: "Penyumbatan" },
        { value: "Bersuara", text: "Bersuara" },
        { value: "Butuh Bantuan Alat", text: "Butuh Bantuan Alat" }
      ],
      mCRPKesadaran: [
        { value: "Sadar Betul", text: "Sadar Betul" },
        { value: "Belum Sadar Betul", text: "Belum Sadar Betul" },
        { value: "Tidur Dalam", text: "Tidur Dalam" }
      ],
      mRanapKamar: [],
      dataRuangPulih: [],
      chartOptions: {
        responsive: true
      },
      openPemantauan: false,
      rowEditPemantauan: {},
      mrValidationPemantauan: {},
      
      patientData: {},
      loading: {
          patientInfo: false,
      },
      mRanapBangsal: [],
    }
  },
  mounted() {
    this.apiGet() 
    this.getPatientInfo()
    setTimeout(()=>{
      if(this.$refs['ttdDokterAnestesi']){
        this.$refs['ttdDokterAnestesi'].fromDataURL(this.row.arancrp_ttd_dokter_anestesi)
      } 
      if(this.$refs['ttdPerawatAnestesi']){
        this.$refs['ttdPerawatAnestesi'].fromDataURL(this.row.arancrp_ttd_asisten_anestesi)
      } 
    },2000)   
  },
  methods: {
    
    output(refs,field) {
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.row[field] = data
        setTimeout(()=>{
          this.autoSave(this.row,true)
        },100)
      }
    },
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.row[field] = null
      setTimeout(()=>{
        this.autoSave(this.row,true)
      },100)
    },
    deletePemantauan(v){
      this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menghapus data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.autoSave(this.row,true)
          
          let data = v
          data.type = 'delete-data-pemantauan'
          
          this.loadingOverlay = true
          Gen.apiRest(
          "/do/" + this.menuPage, {
              data: data
          },
          "POST"
          ).then(res => {
            this.loadingOverlay = false
            
            let resp = res.data
            resp.statusType = 200
            this.$swal({
                title: resp.message,
                icon: resp.status,
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
              this.apiGet()
            })
          }).catch(err => {
            this.loadingOverlay = false
            if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        }
      })
    },
    doOpenPemantauan(id=null,row={}){
      if(!id){
        this.rowEditPemantauan = {
          aranpp_arano_id: this.row.arano_id, 
          aranpp_aranr_id: this.row.arano_aranr_id,
          aranpp_arancrp_id: this.row.arancrp_id,
          aranpp_tanggal: moment().format('YYYY-MM-DD'),
          aranpp_waktu: moment().format('HH:mm'),
          aranpp_id: null
        }
      }else{
        this.rowEditPemantauan = row
      }
      this.openPemantauan = true
    },

    doSubmitRuangPulih(){
      this.$refs['VFormPemantauan'].validate().then(success => {
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              this.autoSave(this.row,true)

              let data = this.rowEditPemantauan
              data.type = 'submit-data-pemantauan'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                  this.openPemantauan = false
                  this.apiGet()
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    },

    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.query.regId){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },
   
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data,isSave=false) {
        data.type = 'auto-save'
        if(!data.isEdit || isSave){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),

    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapOperasiPerawatBedah'}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    }
  },
  watch:{
    row: {
        handler(v) {
            this.autoSave(v)
        },
        deep: true
    },
    ald1Total(v){
      setTimeout(()=>{
          this.row.arancrp_ald1_total = v
      },250)
    },
    ald2Total(v){
      setTimeout(()=>{
          this.row.arancrp_ald2_total = v
      },250)
    },
    stsTotal(v){
      setTimeout(()=>{
          this.row.arancrp_sts_total = v
      },250)
    }
  }
}
</script>