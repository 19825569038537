<template>
<div class="card">
  <div class="card-header bg-white">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="card-title font-weight-semibold">Tambah Monitoring Balance Cairan</h6>
      <button variant="info" type="button" class="btn btn-info btn-labeled btn-labeled-left" @click="openHistory()">
        <b><i class="icon-history"></i></b>
        <span>Lihat Data Balance Cairan</span>
      </button>
    </div>
  </div>
  <validation-observer ref="VFormBalanceCairan">
    <b-form @submit.prevent="doSubmit()">
      <div class="card-body">
        <div class="wrap_line">
          <h3>Intake</h3>
          <div>
            <h6 class="font-weight-semibold text-indigo-800">Infus</h6>
            <table class="table table-sm table-input table-bordered">
              <thead>
                <tr>
                  <th width="250">Nama Infus</th>
                  <th width="128">Jml. Awal</th>
                  <th width="128">Sisa Infus</th>
                  <th width="158">Program Tetesan</th>
                  <th width="106">Kantong ke</th>
                  <th width="200">Waktu Pemasangan</th>
                  <th width="200">Waktu Habis</th>
                  <th width="88">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, k) in formData.infus" :key="`infus-${k}`">
                  <td class="input-cell">
                    <!-- <v-select :options="$parent.mrCairan" :reduce="opt => opt" label="label" v-model="v.cairan" :key="`infc-${k}`" placeholder="-- Pilih Cairan Infus --" @input="updateRow(formData)" /> -->
                    <b-form-input v-model="v.name" placeholder="Nama Cairan" />

                    <b-badge variant="warning" v-if="v.type">{{ v.type }}</b-badge>
                    <VValidate name="Cairan" v-model="v.name" rules="required" :vid="`cname-${k}`" />
                  </td>
                  <td class="input-cell">
                    <div class="input-group">
                      <b-form-input placeholder="cth. 12" :formatter="Formatter.number" v-model="v.jumlah_awal" :key="`infja-${k}`" @change="updateRow(formData);setWaktuHabis(v)" />
                      <div class="input-group-append">
                        <span class="input-group-text padding-cc">cc</span>
                      </div>
                    </div>

                    <VValidate name="Jumlah Awal" v-model="v.jumlah_awal" rules="required" :vid="`cja-${k}`" />
                  </td>
                  <td class="input-cell">
                    <div class="input-group">
                      <b-form-input placeholder="cth. 12" :formatter="Formatter.number" v-model="v.sisa" :key="`infsi-${k}`" @change="updateRow(formData)" />
                      <div class="input-group-append"><span class="input-group-text padding-cc">cc</span></div>
                    </div>

                    <VValidate name="Sisa Infus" v-model="v.sisa" rules="required" v-if="formData.calculate_status === 'CALCULATED'" :vid="`csi-${k}`" />
                  </td>
                  <td class="input-cell">
                    <div class="input-group">
                      <b-form-input placeholder="cth. 12" :formatter="Formatter.number" v-model="v.tetesan" :key="`inft-${k}`" @change="updateRow(formData);setWaktuHabis(v)" />
                      <div class="input-group-append options-tetesan">
                        <select class="form-control pl-0 pr-0" style="font-size: 12px;" v-model="v.tetesan_type" @change="updateRow(formData);setWaktuHabis(v)">
                          <option :value="v" v-for="(v, k) in MonitoringOptions.BalanceCairan.tetesanType" :key="`tetesan-${k}`">
                            {{ v }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <VValidate name="Program Tetesan" v-model="v.tetesan" rules="required" :vid="`ctetesan-${k}`" />
                  </td>
                  <td class="input-cell">
                    <b-form-input placeholder="cth. 12" :formatter="Formatter.number" v-model="v.botol_ke" :key="`infbk-${k}`" @change="updateRow(formData)" />

                    <VValidate name="Botol" v-model="v.botol_ke" rules="required" :vid="`cbotol-${k}`" />
                  </td>
                  <td class="input-cell">
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                      <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.waktu_pemasangan" :key="`infwp-${k}`" @change="updateRow(formData);setWaktuHabis(v)">
                      </vue-timepicker>
                    </div>

                    <VValidate name="Waktu Pemasangan" v-model="v.waktu_pemasangan" rules="required" :vid="`cwp-${k}`" />
                  </td>
                  <td class="input-cell">
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                      <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.waktu_habis" :key="`infwh-${k}`" @change="updateRow(formData)">
                      </vue-timepicker>
                    </div>

                    <VValidate name="Waktu Habis" v-model="v.waktu_habis" rules="required" v-if="formData.calculate_status === 'CALCULATED'" :vid="`cwh-${k}`" />
                  </td>
                  <td>
                    <a href="javascript:;" @click="removeOption(k, 'infus')" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus" v-if="formData.infus.length > 1">
                      <i class="icon-cancel-circle2"></i>
                    </a>

                    <a href="javascript:;" @click="addOption('infus')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Tambah Data" v-if="(formData.infus.length - 1) == k">
                      <i class="icon-plus2"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th class="text-right">Total Infus</th>
                  <td colspan="7">
                    <strong class="text-indigo-800">{{ totalInfus }}cc</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="mt-3">
            <h6 class="font-weight-semibold text-indigo-800">Transfusi Darah</h6>
            <div>
              <b-form-group>
                <label>Ada Transfusi Darah?</label>
                <b-form-radio-group id="radio-group-1" v-model="formData.is_transfusi_darah" :options="Config.mr.yesNoOptV2" @change="updateRow(formData)"></b-form-radio-group>
              </b-form-group>
            </div>
            <div class="transfusiBlock mt-2" v-if="formData.is_transfusi_darah == 'Y'">
              <table class="table table-sm table-input table-bordered">
                <thead>
                  <tr>
                    <th width="250">Nama Darah</th>
                    <th width="128">Jml. Awal</th>
                    <th width="128">Sisa Darah</th>
                    <th width="128">Program Tetesan</th>
                    <th width="106">Kantong ke</th>
                    <th width="200">Waktu Pemasangan</th>
                    <th width="200">Waktu Habis</th>
                    <th width="108">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in formData.transfusi_darah" :key="`transd-${k}`">
                    <td class="input-cell">
                      <v-select :options="$parent.mrDarahBalanceCairan" :reduce="opt => opt" label="label" v-model="v.jenis" :key="`transdc-${k}`" placeholder="-- Jenis Transfusi Darah --" @input="updateRow(formData)" />

                      <VValidate name="Jenis Transfusi Darah" v-model="v.jenis" rules="required" />
                    </td>
                    <td class="input-cell">
                      <div class="input-group">
                        <b-form-input placeholder="cth. 12" :formatter="Formatter.number" v-model="v.jumlah_awal" :key="`transdja-${k}`" @change="updateRow(formData)" />
                        <div class="input-group-append">
                          <span class="input-group-text padding-cc">cc</span>
                        </div>
                      </div>

                      <VValidate name="Jumlah Awal" v-model="v.jumlah_awal" rules="required" />
                    </td>
                    <td class="input-cell">
                      <div class="input-group">
                        <b-form-input placeholder="cth. 12" :formatter="Formatter.number" v-model="v.sisa" :key="`transdsi-${k}`" @change="updateRow(formData)" />
                        <div class="input-group-append"><span class="input-group-text padding-cc">cc</span></div>
                      </div>

                      <VValidate name="Sisa Infus" v-model="v.sisa" rules="required" />
                    </td>
                    <td class="input-cell">
                      <div class="input-group">
                        <b-form-input placeholder="cth. 12" :formatter="Formatter.number" v-model="v.tetesan" :key="`transdt-${k}`" @change="updateRow(formData)" />
                        <div class="input-group-append"><span class="input-group-text">tpm</span></div>
                      </div>

                      <VValidate name="Program Tetesan" v-model="v.tetesan" rules="required" />
                    </td>
                    <td class="input-cell">
                      <b-form-input placeholder="cth. 12" :formatter="Formatter.number" v-model="v.botol_ke" :key="`transdbk-${k}`" @change="updateRow(formData)" />

                      <VValidate name="Botol" v-model="v.botol_ke" rules="required" />
                    </td>
                    <td class="input-cell">
                      <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.waktu_pemasangan" :key="`transdwp-${k}`" @change="updateRow(formData)">
                        </vue-timepicker>
                      </div>

                      <VValidate name="Waktu Pemasangan" v-model="v.waktu_pemasangan" rules="required" />
                    </td>
                    <td class="input-cell">
                      <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.waktu_habis" :key="`transdwh-${k}`" @change="updateRow(formData)">
                        </vue-timepicker>
                      </div>

                      <VValidate name="Waktu Habis" v-model="v.waktu_habis" rules="required" />
                    </td>
                    <td>
                      <a href="javascript:;" @click="removeOption(k, 'infus')" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus" v-if="formData.infus.length > 1">
                        <i class="icon-cancel-circle2"></i>
                      </a>

                      <a href="javascript:;" @click="addOption('infus')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Tambah Data" v-if="(formData.infus.length - 1) == k">
                        <i class="icon-plus2"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="text-right">Total Darah</th>
                    <td colspan="7">
                      <strong class="text-indigo-800">{{ totalTransfusiDarah }}cc</strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="transfusiBlock mt-2" id="noTransfusi"></div>
          </div>
          <div class="mt-3">
            <h6 class="font-weight-semibold text-indigo-800">Minum</h6>
            <div class="row g-2">
              <div class="col-md-8">
                <table class="table table-bordered table-sm table-input">
                  <thead>
                    <tr>
                      <th>Jam</th>
                      <th>Jumlah Minum</th>
                      <th width="108">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, k) in formData.minum" :key="`minum-${k}`">
                      <td class="input-cell">
                        <div class="input-group">
                          <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.time" :key="`minumt-${k}`" @change="updateRow(formData)">
                          </vue-timepicker>

                        </div>
                        <VValidate name="Jam" v-model="v.time" :rules="mrValidation.minum_time" />
                      </td>
                      <td class="input-cell">
                        <div class="input-group">
                          <b-form-input type="tel" :formatter="Formatter.number" v-model="v.total" class="form-control" placeholder="cth. 12" :key="`minumto-${k}`" @change="updateRow(formData)" />
                          <div class="input-group-append">
                            <span class="input-group-text padding-cc">cc</span>
                          </div>
                        </div>

                        <VValidate name="Jumlah" v-model="v.total" :rules="mrValidation.minum_amount" />
                      </td>
                      <td>
                        <a href="javascript:;" @click="removeOption(k, 'minum')" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus" v-if="formData.minum.length > 1">
                          <i class="icon-cancel-circle2"></i>
                        </a>

                        <a href="javascript:;" @click="addOption('minum')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Tambah Data" v-if="(formData.minum.length - 1) == k">
                          <i class="icon-plus2"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th class="text-right">Total Minum</th>
                      <td colspan="2">
                        <strong class="text-indigo-800">{{ totalMinum }}cc</strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h6 class="font-weight-semibold text-indigo-800">Titrasi Obat</h6>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <table class="table table-sm table-input table-bordered">
                    <thead>
                      <tr>
                        <th width="240">Nama Obat</th>
                        <th width="128">Jam Pemberian</th>
                        <th width="128">Jumlah (cc)</th>
                        <th width="128">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, k) in formData.titrasi_obat" :key="`obt-${k}`">
                        <td class="input-cell">
                          <b-form-input type="text" v-model="v.name" placeholder="Nama Obat" :key="`obtn${k}`" @change="updateRow(formData)" />

                          <VValidate name="Nama Obat" v-model="v.name" :rules="mrValidation.titrasi_obat_name" />
                        </td>
                        <td class="input-cell">
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.time" :key="`obtt-${k}`" @change="updateRow(formData)">
                            </vue-timepicker>

                            <VValidate name="Jam" v-model="v.time" :rules="mrValidation.titrasi_obat_time" />
                          </div>
                        </td>
                        <td class="input-cell">
                          <div class="input-group">
                            <b-form-input type="tel" :formatter="Formatter.number" v-model="v.total" class="form-control" placeholder="cth. 12" :key="`obtto-${k}`" @change="updateRow(formData)" />
                            <div class="input-group-append">
                              <span class="input-group-text padding-cc">cc</span>
                            </div>

                          </div>
                          <VValidate name="Jumlah" v-model="v.total" :rules="mrValidation.titrasi_obat_amount" />
                        </td>
                        <td>
                          <a href="javascript:;" @click="removeOption(k, 'titrasi_obat')" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus">
                            <i class="icon-cancel-circle2"></i>
                          </a>

                          <a href="javascript:;" @click="addOption('titrasi_obat')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Tambah Data" v-if="(formData.titrasi_obat.length - 1) == k">
                            <i class="icon-plus2"></i>
                          </a>
                        </td>
                      </tr>

                      <tr v-if="!formData.titrasi_obat.length">
                        <td colspan="10">
                          <div class="text-center pt-2 pb-2">
                            <b-button variant="outline-primary" type="button" @click="addOption('titrasi_obat')">
                              <i class="icon-plus2"></i> Tambah Obat
                            </b-button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="formData.titrasi_obat.length">
                      <tr>
                        <th class="text-right">Total Titrasi Obat</th>
                        <td colspan="3">
                          <strong class="text-indigo-800">{{ totalTitriasiObat }}cc</strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h6 class="font-weight-semibold text-indigo-800">Intake Lainnya</h6>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <table class="table table-sm table-input table-bordered">
                    <thead>
                      <tr>
                        <th width="240">Nama Cairan</th>
                        <th width="128">Jam Pemberian</th>
                        <th width="128">Jumlah (cc)</th>
                        <th width="128">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, k) in formData.intake_lainnya" :key="`inptlain-${k}`">
                        <td class="input-cell">
                          <b-form-input type="text" v-model="v.name" placeholder="Nama Cairan" :key="`inptlainn${k}`" @change="updateRow(formData)" />

                          <VValidate name="Nama Cairan" v-model="v.name" :rules="mrValidation.intake_lainnya_name" />
                        </td>
                        <td class="input-cell">
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.time" :key="`inptlaint-${k}`" @change="updateRow(formData)">
                            </vue-timepicker>

                            <VValidate name="Jam" v-model="v.time" :rules="mrValidation.intake_lainnya_time" />
                          </div>
                        </td>
                        <td class="input-cell">
                          <div class="input-group">
                            <b-form-input type="tel" :formatter="Formatter.number" v-model="v.total" class="form-control" placeholder="cth. 12" :key="`obtto-${k}`" @change="updateRow(formData)" />
                            <div class="input-group-append">
                              <span class="input-group-text padding-cc">cc</span>
                            </div>
                          </div>

                          <VValidate name="Jumlah" v-model="v.total" :rules="mrValidation.intake_lainnya_amount" />
                        </td>
                        <td>
                          <a href="javascript:;" @click="removeOption(k, 'intake_lainnya')" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus">
                            <i class="icon-cancel-circle2"></i>
                          </a>

                          <a href="javascript:;" @click="addOption('intake_lainnya')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Tambah Data" v-if="(formData.intake_lainnya.length - 1) == k">
                            <i class="icon-plus2"></i>
                          </a>
                        </td>
                      </tr>

                      <tr v-if="!formData.intake_lainnya.length">
                        <td colspan="10">
                          <div class="text-center pt-2 pb-2">
                            <b-button variant="outline-primary" type="button" @click="addOption('intake_lainnya')">
                              <i class="icon-plus2"></i> Tambah Intake Lainnya
                            </b-button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="formData.intake_lainnya.length">
                      <tr>
                        <th class="text-right">Total Cairan</th>
                        <td colspan="3">
                          <strong class="text-indigo-800">{{ totalIntakeLainnya }}cc</strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h6 class="font-weight-semibold text-indigo-800">Total Intake</h6>
            <div class="row">
              <div class="col-md-2">
                <div>
                  <label for="intakeTotal">Total Intake<strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <b-form-input readonly :value="totalIntake" />
                    <div class="input-group-append"><span class="input-group-text padding-cc">cc</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap_line">
          <h3>Output</h3>
          <div class="row g-2">
            <div class="col-md-10">
              <div class="row g-3">
                <div class="col-md-6">
                  <div>
                    <h6 class="font-weight-semibold text-indigo-800">Urine</h6>
                    <table class="table table-bordered table-sm table-input">
                      <thead>
                        <tr>
                          <th>Jam</th>
                          <th>Jumlah Urine</th>
                          <th width="108">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, k) in formData.urine" :key="`urine-${k}`">
                          <td class="input-cell">
                            <div class="input-group">
                              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                              <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.time" :key="`urinet-${k}`" @change="updateRow(formData)">
                              </vue-timepicker>

                              <VValidate name="Jam" v-model="v.time" :rules="mrValidation.urine_time" />
                            </div>
                          </td>
                          <td class="input-cell">
                            <div class="input-group">
                              <b-form-input type="tel" :formatter="Formatter.number" v-model="v.total" class="form-control" placeholder="cth. 12" :key="`urineto-${k}`" @change="updateRow(formData)" />
                              <div class="input-group-append">
                                <span class="input-group-text padding-cc">cc</span>
                              </div>
                            </div>

                            <VValidate name="Jumlah" v-model="v.total" :rules="mrValidation.urine_amount" />
                          </td>
                          <td>
                            <a href="javascript:;" @click="removeOption(k, 'urine')" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus">
                              <i class="icon-cancel-circle2"></i>
                            </a>

                            <a href="javascript:;" @click="addOption('urine')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Tambah Data" v-if="(formData.urine.length - 1) == k">
                              <i class="icon-plus2"></i>
                            </a>
                          </td>
                        </tr>

                        <tr v-if="!formData.urine.length">
                          <td colspan="10">
                            <div class="text-center pt-2 pb-2">
                              <b-button variant="outline-primary" type="button" @click="addOption('urine')">
                                <i class="icon-plus2"></i> Tambah Urine
                              </b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="formData.urine.length">
                        <tr>
                          <th class="text-right">Total Urine</th>
                          <td colspan="2">
                            <strong class="text-indigo-800">{{ totalUrine }}cc</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <h6 class="font-weight-semibold text-indigo-800">Muntah</h6>
                    <table class="table table-bordered table-sm table-input">
                      <thead>
                        <tr>
                          <th>Jam</th>
                          <th>Jumlah Muntah</th>
                          <th width="108">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, k) in formData.muntah" :key="`muntah-${k}`">
                          <td class="input-cell">
                            <div class="input-group">
                              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                              <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.time" :key="`muntaht-${k}`" @change="updateRow(formData)">
                              </vue-timepicker>

                              <VValidate name="Jam" v-model="v.time" :rules="mrValidation.muntah_time" />
                            </div>
                          </td>
                          <td class="input-cell">
                            <div class="input-group">
                              <b-form-input type="tel" :formatter="Formatter.number" v-model="v.total" class="form-control" placeholder="cth. 12" :key="`muntahto-${k}`" @change="updateRow(formData)" />
                              <div class="input-group-append">
                                <span class="input-group-text padding-cc">cc</span>
                              </div>
                            </div>

                            <VValidate name="Jumlah" v-model="v.total" :rules="mrValidation.muntah_amount" />
                          </td>
                          <td>
                            <a href="javascript:;" @click="removeOption(k, 'muntah')" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus">
                              <i class="icon-cancel-circle2"></i>
                            </a>

                            <a href="javascript:;" @click="addOption('muntah')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Tambah Data" v-if="(formData.muntah.length - 1) == k">
                              <i class="icon-plus2"></i>
                            </a>
                          </td>
                        </tr>

                        <tr v-if="!formData.muntah.length">
                          <td colspan="10">
                            <div class="text-center pt-2 pb-2">
                              <b-button variant="outline-primary" type="button" @click="addOption('muntah')">
                                <i class="icon-plus2"></i> Tambah Muntah
                              </b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="formData.muntah.length">
                        <tr>
                          <th class="text-right">Total Muntah</th>
                          <td colspan="2">
                            <strong class="text-indigo-800">{{ totalMuntah }}cc</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <h6 class="font-weight-semibold text-indigo-800">BAB</h6>
                    <table class="table table-bordered table-sm table-input">
                      <thead>
                        <tr>
                          <th>Jam</th>
                          <th>Jumlah BAB</th>
                          <th width="108">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, k) in formData.bab" :key="`bab-${k}`">
                          <td class="input-cell">
                            <div class="input-group">
                              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                              <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.time" :key="`babt-${k}`" @change="updateRow(formData)">
                              </vue-timepicker>

                              <VValidate name="Jam" v-model="v.time" :rules="mrValidation.bab_time" />
                            </div>
                          </td>
                          <td class="input-cell">
                            <div class="input-group">
                              <b-form-input type="tel" :formatter="Formatter.number" v-model="v.total" class="form-control" placeholder="cth. 12" :key="`babto-${k}`" @change="updateRow(formData)" />
                              <div class="input-group-append">
                                <span class="input-group-text padding-cc">cc</span>
                              </div>
                            </div>

                            <VValidate name="Jumlah" v-model="v.total" :rules="mrValidation.bab_amount" />
                          </td>
                          <td>
                            <a href="javascript:;" @click="removeOption(k, 'bab')" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus">
                              <i class="icon-cancel-circle2"></i>
                            </a>

                            <a href="javascript:;" @click="addOption('bab')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Tambah Data" v-if="(formData.bab.length - 1) == k">
                              <i class="icon-plus2"></i>
                            </a>
                          </td>
                        </tr>

                        <tr v-if="!formData.bab.length">
                          <td colspan="10">
                            <div class="text-center pt-2 pb-2">
                              <b-button variant="outline-primary" type="button" @click="addOption('bab')">
                                <i class="icon-plus2"></i> Tambah BAB
                              </b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="formData.bab.length">
                        <tr>
                          <th class="text-right">Total BAB</th>
                          <td colspan="2">
                            <strong class="text-indigo-800">{{ totalBab }}cc</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <h6 class="font-weight-semibold text-indigo-800">Pendarahan/Phlebotomy</h6>
                    <table class="table table-bordered table-sm table-input">
                      <thead>
                        <tr>
                          <th>Jam</th>
                          <th>Jumlah Darah</th>
                          <th width="108">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, k) in formData.pendarahan" :key="`pendarahan-${k}`">
                          <td class="input-cell">
                            <div class="input-group">
                              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                              <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.time" :key="`pendarahant-${k}`" @change="updateRow(formData)">
                              </vue-timepicker>

                              <VValidate name="Jam" v-model="v.time" :rules="mrValidation.pendarahan_time" />
                            </div>
                          </td>
                          <td class="input-cell">
                            <div class="input-group">
                              <b-form-input type="tel" :formatter="Formatter.number" v-model="v.total" class="form-control" placeholder="cth. 12" :key="`pendarahanto-${k}`" @change="updateRow(formData)" />
                              <div class="input-group-append">
                                <span class="input-group-text padding-cc">cc</span>
                              </div>
                            </div>

                            <VValidate name="Jumlah" v-model="v.total" :rules="mrValidation.pendarahan_amount" />
                          </td>
                          <td>
                            <a href="javascript:;" @click="removeOption(k, 'pendarahan')" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus">
                              <i class="icon-cancel-circle2"></i>
                            </a>

                            <a href="javascript:;" @click="addOption('pendarahan')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Tambah Data" v-if="(formData.pendarahan.length - 1) == k">
                              <i class="icon-plus2"></i>
                            </a>
                          </td>
                        </tr>

                        <tr v-if="!formData.pendarahan.length">
                          <td colspan="10">
                            <div class="text-center pt-2 pb-2">
                              <b-button variant="outline-primary" type="button" @click="addOption('pendarahan')">
                                <i class="icon-plus2"></i> Tambah Pendarahan
                              </b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="formData.pendarahan.length">
                        <tr>
                          <th class="text-right">Total Pendarahan</th>
                          <td colspan="2">
                            <strong class="text-indigo-800">{{ totalPendarahan }}cc</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div>
                <label for="outputIwl">IWL <strong class="text-danger">*</strong></label>
                <div class="input-group">
                  <b-form-input v-model="formData.iwl" @change="updateRow(formData)" />
                  <div class="input-group-append"><span class="input-group-text padding-cc">cc</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-3">
            <h6 class="font-weight-semibold text-indigo-800">Output Lainnya</h6>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <table class="table table-sm table-input table-bordered">
                    <thead>
                      <tr>
                        <th width="240">Nama Cairan</th>
                        <th width="128">Jam Pengeluaran</th>
                        <th width="128">Jumlah (cc)</th>
                        <th width="128">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, k) in formData.output_lainnya" :key="`ouptlain-${k}`">
                        <td class="input-cell">
                          <b-form-input type="text" v-model="v.name" placeholder="Nama Cairan" :key="`ouptlainn${k}`" @change="updateRow(formData)" />

                          <VValidate name="Nama Cairan" v-model="v.name" :rules="mrValidation.output_lainnya_name" />
                        </td>
                        <td class="input-cell">
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.time" :key="`ouptlaint-${k}`" @change="updateRow(formData)">
                            </vue-timepicker>

                            <VValidate name="Jam" v-model="v.time" :rules="mrValidation.output_lainnya_time" />
                          </div>
                        </td>
                        <td class="input-cell">
                          <div class="input-group">
                            <b-form-input type="tel" :formatter="Formatter.number" v-model="v.total" class="form-control" placeholder="cth. 12" :key="`obtto-${k}`" @change="updateRow(formData)" />
                            <div class="input-group-append">
                              <span class="input-group-text padding-cc">cc</span>
                            </div>
                          </div>

                          <VValidate name="Jumlah" v-model="v.total" :rules="mrValidation.output_lainnya_amount" />
                        </td>
                        <td>
                          <a href="javascript:;" @click="removeOption(k, 'output_lainnya')" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus">
                            <i class="icon-cancel-circle2"></i>
                          </a>

                          <a href="javascript:;" @click="addOption('output_lainnya')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Tambah Data" v-if="(formData.output_lainnya.length - 1) == k">
                            <i class="icon-plus2"></i>
                          </a>
                        </td>
                      </tr>

                      <tr v-if="!formData.output_lainnya.length">
                        <td colspan="10">
                          <div class="text-center pt-2 pb-2">
                            <b-button variant="outline-primary" type="button" @click="addOption('output_lainnya')">
                              <i class="icon-plus2"></i> Tambah Output Lainnya
                            </b-button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="formData.output_lainnya.length">
                      <tr>
                        <th class="text-right">Total Cairan</th>
                        <td colspan="3">
                          <strong class="text-indigo-800">{{ totalOutputLainnya }}cc</strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div>
                <label for="outputTotal">Total Output<strong class="text-danger">*</strong></label>
                <div class="input-group">
                  <b-form-input readonly :value="totalOutput" />
                  <div class="input-group-append"><span class="input-group-text padding-cc">cc</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <b-form-checkbox v-model="formData.calculate_status" value="CALCULATED" unchecked-value="NOT_CALCULATED" :disabled="true">
            Hitung Balance Cairan?
          </b-form-checkbox>
        </div>
        <div v-if="formData.calculate_status === 'CALCULATED'" class="mt-3">
          <div class="row">
            <div class="col-md-3">
              <div>
                <label for="balanceTotal">Total Balance Cairan<strong class="text-danger">*</strong></label>
                <div class="input-group">
                  <b-form-input readonly :value="totalBalanceCairan" />
                  <div class="input-group-append"><span class="input-group-text padding-cc">cc</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <a href="javascript:;" class="btn" @click="$router.back()">Kembali</a>
          <button type="submit" class="btn btn-labeled btn-labeled-left btn-success">
            <b><i class="icon-checkmark"></i></b>
            <span>Simpan</span>
          </button>
        </div>
      </div>
    </b-form>
  </validation-observer>

  <b-modal id="historyData" size="xl" hide-footer title="Riwayat Balance Cairan">
    <div class="table-responsive">
      <table class="table table-bordered table-sm" style="min-width:2500px;">
        <thead class="text-center">
          <tr>
            <th rowspan="3" width="112">Waktu</th>
            <th rowspan="3" width="86">Total Balance Cairan</th>
            <th colspan="11">Intake</th>
            <th colspan="12">Output</th>
          </tr>
          <tr>
            <!-- Intake -->
            <th colspan="2">Infus</th>
            <th colspan="2">Transfusi Darah</th>
            <th colspan="2">Minum</th>
            <th colspan="2">Titrasi Obat</th>
            <th colspan="2">Intake Lainnya</th>
            <th rowspan="2" width="86">Total Intake</th>
            <th colspan="2">Urine</th>
            <th colspan="2">Muntah</th>
            <th colspan="2">BAB</th>
            <th colspan="2">Pendarahan/Phlebotomy</th>
            <th colspan="2">Output Lainnya</th>
            <th rowspan="2">IWL</th>
            <th rowspan="2" width="86">Total Output</th>
          </tr>
          <tr>

            <!-- Infus -->
            <th>Pemberian</th>
            <th>Total</th>

            <!-- Transfusi Darah -->
            <th>Pemberian</th>
            <th>Total</th>

            <!-- Minum -->
            <th>Pemberian</th>
            <th>Total</th>

            <!-- Titrasi Obat -->
            <th>Pemberian</th>
            <th>Total</th>

            <!-- Intake Lainnya -->
            <th>Pemberian</th>
            <th>Total</th>

            <!-- Urine -->
            <th>Pengeluaran</th>
            <th>Total</th>

            <!-- Muntah -->
            <th>Pengeluaran</th>
            <th>Total</th>

            <!-- BAB -->
            <th>Pengeluaran</th>
            <th>Total</th>

            <!-- Pendarahan -->
            <th>Pengeluaran</th>
            <th>Total</th>

            <!-- Output Lainnya -->
            <th>Pengeluaran</th>
            <th>Total</th>

            <!-- Output -->
            <!-- <th>IWL</th>
                      <th>Urine</th>
                      <th>Muntah</th>
                      <th>BAB</th>
                      <th>Pendarahan</th> -->
            <!-- <th>Detail Output</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v, k) in historyData.data" :key="`history-${k}`">
            <td>{{ v.created_date | moment("DD-MM-YYYY HH:mm") }}</td>
            <td>{{ v.total_balance_cairan }}cc</td>
            <td>              
              <ul class="pl-2 mb-0">
                <li class="my-1" v-for="(dtInfus, kInfus) in v.infus" :key="`dtInfus${kInfus}`">
                  <div>
                    {{ dtInfus.name || '-' }}
                    <a href="javascript:;" class="ml-1 infusDetails" v-b-tooltip.right.html :title="`
                        <ul class='m-0 pl-2 text-left'>
                          <li>Jml. Awal: ${dtInfus.jumlah_awal}cc</li>
                          <li>Sisa Infus: ${dtInfus.sisa}cc</li>
                          <li>Prog. Tetesan: ${dtInfus.tetesan}${dtInfus.tetesan_type||'tpm'}</li>
                          <li>Botol Ke: ${dtInfus.botol_ke}</li>
                          <li>Dipasang: ${timeLabel(dtInfus.waktu_pemasangan)}</li>
                          <li>Habis: ${timeLabel(dtInfus.waktu_habis)}</li>
                        </ul>
                      `">
                      <i class="icon-info22 text-info"></i>
                      <b-badge variant="warning" v-if="dtInfus.type" class="ml-1">{{ dtInfus.type }}</b-badge>
                    </a>
                  </div>
                  <div class="d-flex flex-column">
                    <!-- <small class="text-secondary">Jml. Awal: 500cc</small> -->
                    <!-- <small class="text-secondary">Jml. Tambahan: 125cc</small> -->
                    <small class="text-secondary">Sisa Infus: {{ dtInfus.sisa }}cc</small>
                    <!-- <small class="text-secondary">Prog. Tetesan: 5tpm</small> -->
                    <!-- <small class="text-secondary">Kantong ke: 2</small> -->
                    <small class="text-secondary">Dipasang: {{ timeLabel(dtInfus.waktu_pemasangan) }}</small>
                    <small class="text-secondary">Habis: {{ timeLabel(dtInfus.waktu_habis) }}</small>
                  </div>
                </li>
              </ul>
            </td>
            <td>{{ v.total_infus }}cc</td>
            <td>
              <ul class="pl-2 mb-0" v-if="v.is_transfusi_darah == 'Y'">
                <li class="my-1" v-for="(dtTransfusi, kTransfusi) in v.transfusi_darah" :key="`dtTransfusi${kTransfusi}`">
                  <div>
                    {{ dtTransfusi.jenis.label }}
                    <a href="javascript:;" class="ml-1 infusDetails" v-b-tooltip.right.html :title="`
                        <ul class='m-0 pl-2 text-left'>
                          <li>Jml. Awal: ${dtTransfusi.jumlah_awal}cc</li>
                          <li>Sisa Infus: ${dtTransfusi.sisa}cc</li>
                          <li>Prog. Tetesan: ${dtTransfusi.tetesan}tpm</li>
                          <li>Botol Ke: ${dtTransfusi.botol_ke}</li>
                          <li>Dipasang: ${timeLabel(dtTransfusi.waktu_pemasangan)}</li>
                          <li>Habis: ${timeLabel(dtTransfusi.waktu_habis)}</li>
                        </ul>
                      `">
                      <i class="icon-info22 text-info"></i>
                    </a>
                  </div>
                  <div class="d-flex flex-column">
                    <!-- <small class="text-secondary">Jml. Awal: 500cc</small> -->
                    <!-- <small class="text-secondary">Jml. Tambahan: 125cc</small> -->
                    <small class="text-secondary">Sisa Infus: {{ dtTransfusi.sisa }}cc</small>
                    <!-- <small class="text-secondary">Prog. Tetesan: 5tpm</small> -->
                    <!-- <small class="text-secondary">Kantong ke: 2</small> -->
                    <small class="text-secondary">Dipasang: {{ timeLabel(dtTransfusi.waktu_pemasangan) }}</small>
                    <small class="text-secondary">Habis: {{ timeLabel(dtTransfusi.waktu_habis) }}</small>
                  </div>
                </li>

                <span v-if="!(v.transfusi_darah||[]).length">-</span>
              </ul>

              <span v-else>-</span>
            </td>
            <td>{{ v.total_transfusi_darah||0 }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtMinum, kMinum) in v.minum" :key="`dtminum-${kMinum}`">
                  <span>{{ dtMinum.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtMinum.time) }}</small></div>
                </li>
              </ul>
            </td>
            <td>{{ v.total_minum }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtObat, kObat) in v.titrasi_obat" :key="`dtobat-${kObat}`">
                  <span>{{ dtObat.name }} {{ dtObat.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtObat.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.titrasi_obat||[]).length">-</span>
            </td>
            <td>{{ v.total_titrasi_obat }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtIntakeLainnya, kIntakeLainnya) in v.intake_lainnya" :key="`dtintakelainnya-${kIntakeLainnya}`">
                  <span>{{ dtIntakeLainnya.name }} {{ dtIntakeLainnya.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtIntakeLainnya.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.intake_lainnya||[]).length">-</span>
            </td>
            <td>{{ v.total_intake_lainnya }}cc</td>
            <td>{{ v.total_intake }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtUrine, kUrine) in v.urine" :key="`dturine-${kUrine}`">
                  <span>{{ dtUrine.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtUrine.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.urine||[]).length">-</span>
            </td>
            <td>{{ v.total_urine }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtMuntah, kMuntah) in v.muntah" :key="`dtmuntah-${kMuntah}`">
                  <span>{{ dtMuntah.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtMuntah.time) }}</small></div>
                </li>
              </ul>
            </td>
            <td>{{ v.total_muntah }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtBab, kBab) in v.bab" :key="`dtbab-${kBab}`">
                  <span>{{ dtBab.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtBab.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.bab||[]).length">-</span>
            </td>
            <td>{{ v.total_bab }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtPendarahan, kPendarahan) in v.pendarahan" :key="`dtpendarahan-${kPendarahan}`">
                  <span>{{ dtPendarahan.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtPendarahan.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.pendarahan||[]).length">-</span>
            </td>
            <td>{{ v.total_pendarahan }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtOutputLainnya, kOutputLainnya) in v.output_lainnya" :key="`dtoutput_lainnya-${kOutputLainnya}`">
                  <span>{{ dtOutputLainnya.name }} {{ dtOutputLainnya.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtOutputLainnya.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.output_lainnya||[]).length">-</span>
            </td>
            <td>{{ v.total_output_lainnya }}cc</td>
            <td>{{ v.iwl }}cc</td>
            <td>{{ v.total_output }}cc</td>            
          </tr>

          <tr v-if="historyData.page.total_data == 0">
            <td colspan="99">
              <span v-if="historyData.page.is_loading">Loading...</span>
              <span v-else>Data Balance Cairan Belum Tersedia</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-end" v-if="historyData.page.total_data > historyData.page.per_page">
      <b-pagination class="mb-0" v-model="$parent.pageNoTData" :per-page="historyData.page.per_page" :total-rows="historyData.page.total_data" />
    </div>
  </b-modal>
</div>
</template>

<script>
import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'
import Config from '@/libs/Config.js'
import Gen from '@/libs/Gen'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

const _ = global._

export default {
  name: "FormData",

  components: {
    VueTimepicker
  },

  data() {
    return {
      formData: {
        infus: [{ tetesan_type: 'tpm' }],
        transfusi_darah: [{}],
        minum: [{}],

        titrasi_obat: [],
        intake_lainnya: [],
        urine: [],
        muntah: [],
        bab: [],
        pendarahan: [],
        output_lainnya: [],
      },
    }
  },

  methods: {
    setWaktuHabis: _.debounce(function(data){
      if(!data.jumlah_awal || !data.waktu_pemasangan || !data.tetesan) return

      // Set Waktu Pemasangan Into Date Object Type
      const waktuPemasangan = new Date()
      waktuPemasangan.setHours((+data.waktu_pemasangan.HH), (+data.waktu_pemasangan.mm))

      let tambahanMenit = 0

      // If Program Tetesan is tpm/menit
      if(data.tetesan_type == 'tpm'){
        tambahanMenit = this.calculateWaktuHabisTPM(data) // Format dalam waktu menit
      } else if(data.tetesan_type == 'cc/jam') {
        tambahanMenit = Math.floor(data.jumlah_awal / data.tetesan) * 60
      }
      
      if(tambahanMenit > 0){
        // Set Waktu Habis Into Date Object Type
        const waktuHabis = new Date(waktuPemasangan.getTime() + tambahanMenit * 60000)
        const hours = waktuHabis.getHours()
        const minutes = waktuHabis.getMinutes()
  
        this.$set(data, 'waktu_habis', 
          { 
            "HH": (hours).toString().padStart(2, "0"),
            "mm": (minutes).toString().padStart(2, "0")
          }
        )
      }
    }, 0),
    calculateWaktuHabisTPM(v){
      const tpm = v.jumlah_awal / v.tetesan
      const divider = tpm / 2

      return Math.round((tpm + divider) * 10)
    },
    timeLabel(timeObj){
      if(!timeObj) return '-'
      if(typeof timeObj != 'object') return '-'

      const { HH, mm } = timeObj
      return `${HH}:${mm}`
    },
    addOption(keyData) {
      if(keyData == 'infus'){
        this.formData[keyData].push({ type: 'UBS', tetesan_type: 'tpm' })
      } else {
        this.formData[keyData].push({})
      }
    },
    removeOption(key, keyData) {
      this.formData[keyData].splice(key, 1)
    },

    setFormData(newData) {
      this.$set(this, 'formData', {
        ...this.formData,
        ...newData
      })
    },

    openHistory() {
      this.$bvModal.show('historyData')

      this.$parent.getHistoryData(1)
    },
    updateRow: _.debounce(function(data){
      Gen.apiRest(
        "/do/" + "RanapBalanceCairan", {
          data: {
            type: !data.id ? "add-balance-cairan" : "update-balance-cairan",
            id_registrasi: this.$route.query.regId,
            ...data,
            total_infus: this.totalInfus,
            total_transfusi_darah: this.totalTransfusiDarah,
            total_minum: this.totalMinum,
            total_titrasi_obat: this.totalTitriasiObat,
            total_intake_lainnya: this.totalIntakeLainnya,
            total_intake: this.totalIntake,
            total_urine: this.totalUrine,
            total_muntah: this.totalMuntah,
            total_bab: this.totalBab,
            total_pendarahan: this.totalPendarahan,
            total_output_lainnya: this.totalOutputLainnya,
            total_output: this.totalOutput,
            total_balance_cairan: this.totalBalanceCairan,
          }
        },
        "POST"
      ).then(resp => {
        if(!data.id){
          this.$set(data, 'id', resp.data.id)
        }
      })
    }, 1000),
    doSubmit() {
      this.$refs['VFormBalanceCairan'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            const payload = {
              ...this.formData,
              total_infus: this.totalInfus,
              total_transfusi_darah: this.totalTransfusiDarah,
              total_minum: this.totalMinum,
              total_titrasi_obat: this.totalTitriasiObat,
              total_intake_lainnya: this.totalIntakeLainnya,
              total_intake: this.totalIntake,
              total_urine: this.totalUrine,
              total_muntah: this.totalMuntah,
              total_bab: this.totalBab,
              total_pendarahan: this.totalPendarahan,
              total_output_lainnya: this.totalOutputLainnya,
              total_output: this.totalOutput,
              total_balance_cairan: this.totalBalanceCairan,
            }

            this.$parent.doSubmitBalanceCairan({
              ...payload,
              type: 'submit'
            })
          }
        })

      })
    }
  },

  computed: {
    optionsEWS() {
      return MonitoringOptions.EWS
    },
    MonitoringOptions() {
      return MonitoringOptions
    },

    Formatter() {
      return Formatter
    },

    historyData() {
      return this.$parent.tHistoryData
    },

    objMrKesadaran() {
      return this.optionsEWS.mrKesadaran.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    },

    mrValidation() {
      return this.$parent.mrValidation
    },

    Config() {
      return Config
    },

    totalOutput() {
      const {
        iwl,
      } = this.formData

      const total = +(
        (+iwl || 0) + (+this.totalUrine || 0) + (+this.totalMuntah || 0) +
        (+this.totalBab || 0) + (+this.totalPendarahan || 0) + (+this.totalOutputLainnya || 0)
      )

      return +total.toFixed(1)
    },

    totalInfus() {
      const arrInfus = this.formData.infus.filter(dtInfus => dtInfus.sisa).map(dtInfus => (+(dtInfus.jumlah_awal||0) - (+dtInfus.sisa||0)))
        .reduce((accVal, curVal) => {
          return accVal + curVal
        }, 0)

      return +(arrInfus || 0).toFixed(1)
    },

    totalTransfusiDarah() {
      const arrTransfusiDarah = this.formData.transfusi_darah.map(dtTransfusiDarah => (+dtTransfusiDarah.jumlah_awal - (+dtTransfusiDarah.sisa)))
        .reduce((accVal, curVal) => {
          return accVal + curVal
        }, 0)

      return +(arrTransfusiDarah || 0).toFixed(1)
    },

    totalTitriasiObat() {
      const arrObat = this.formData.titrasi_obat.map(dtObat => (+dtObat.total))
        .reduce((accVal, curVal) => {
          return accVal + curVal
        }, 0)

      return +(arrObat || 0).toFixed(1)
    },

    totalMinum() {
      const arrMinum = this.formData.minum.map(dtMinum => (+dtMinum.total))
        .reduce((accVal, curVal) => {
          return accVal + curVal
        }, 0)

      return +(arrMinum || 0).toFixed(1)
    },

    totalIntakeLainnya() {
      const arrIntakeLainnya = this.formData.intake_lainnya.map(dtIntakeLainnya => (+dtIntakeLainnya.total))
        .reduce((accVal, curVal) => {
          return accVal + curVal
        }, 0)

      return +(arrIntakeLainnya || 0).toFixed(1)
    },

    totalOutputLainnya() {
      const arrOutputLainnya = this.formData.output_lainnya.map(dtOutputLainnya => (+dtOutputLainnya.total))
        .reduce((accVal, curVal) => {
          return accVal + curVal
        }, 0)

      return +(arrOutputLainnya || 0).toFixed(1)
    },

    totalUrine() {
      const arrUrine = this.formData.urine.map(dtUrine => (+dtUrine.total))
        .reduce((accVal, curVal) => {
          return accVal + curVal
        }, 0)

      return +(arrUrine || 0).toFixed(1)
    },

    totalBab() {
      const arrBab = this.formData.bab.map(dtBab => (+dtBab.total))
        .reduce((accVal, curVal) => {
          return accVal + curVal
        }, 0)

      return +(arrBab || 0).toFixed(1)
    },

    totalMuntah() {
      const arrMuntah = this.formData.muntah.map(dtMuntah => (+dtMuntah.total))
        .reduce((accVal, curVal) => {
          return accVal + curVal
        }, 0)

      return +(arrMuntah || 0).toFixed(1)
    },

    totalPendarahan() {
      const arrPendarahan = this.formData.pendarahan.map(dtPendarahan => (+dtPendarahan.total))
        .reduce((accVal, curVal) => {
          return accVal + curVal
        }, 0)

      return +(arrPendarahan || 0).toFixed(1)
    },

    totalIntake() {
      const total = (this.totalInfus || 0) + (this.totalTitriasiObat || 0) + (+this.totalMinum || 0) + (+this.totalIntakeLainnya || 0) + (+this.totalTransfusiDarah || 0)
      this.$set(this.formData, 'total_intake', total)
      return +total.toFixed(1)
    },

    totalBalanceCairan() {
      const total = (this.totalIntake || 0) - (this.totalOutput || 0)
      this.$set(this.formData, 'total_balance_cairan', total)
      return +total.toFixed(1)
    }
  },

  watch: {
    'formData.is_transfusi_darah'(v) {
      if (v === "N") this.$set(this.formData, 'transfusi_darah', [{}])
    },
  }
}
</script>
