<template>
  <div>
    <validation-observer ref="VFormTindakanOperasi">
      <div class="row">
        <div class="col-md-12">
          <div class="wrap_line mb-2">
            <h6 class="font-weight-semibold">Data Tindakan Saat Ini</h6>

            <table class="table table-sm table-bordered mt-1">
              <thead>
                <tr>
                  <th>Nama Tindakan</th>
                  <th>Inform Consent</th>
                </tr>
                <tr v-for="(v,k) in (dataTindakanOperasi||[])" :key="k">
                  <td>{{v.text}}</td>
                  <td>
                    <template v-if="isParent.rowEdit.arano_from == 'UGD'">
                      <a href="javascript:;" @click="openInformConsentDetailUGD(v)" v-if="v.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                      <i class="icon-file-eye2"></i>
                      </a>
                    </template>
                    <template v-else>
                      <a href="javascript:;" @click="openInformConsentDetail(v,k,0,'')" v-if="v.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                      <i class="icon-file-eye2"></i>
                      </a>
                    </template>
                  </td>
                </tr>
                <tr v-if="!(dataTindakanOperasi||[]).length">
                  <td colspan="2" class="text-center">Tidak ada Data</td>
                </tr>
              </thead>
            </table>
          </div>
          
          <div class="wrap_line mb-0">
            <h6 class="font-weight-semibold">Perubahan Data Tindakan</h6>
            <div class="form-group">
              <label for="">Tindakan Operasi<strong class="text-danger">*</strong></label>
              <div class="req-list-resume">
                <div class="row g-3">
                <template v-for="(v,k) in dataRowOperasi">
                    <div class="col-md-4 col-lg-3" :key="k+'radioData'"
                    v-if="isShowHeadOperasi(k)">
                    <div class="card shadow-0 border">
                        <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                        </div>
                        <div class="card-body py-2">
                        <template v-for="v1,k1 in (v.data||[])">
                            <div v-if="isShowOperasi(k,k1)" :key="k1+'radiosubdatas'">
                            <i
                                class="icon-checkmark-circle text-success align-middle mr-1"></i>
                            <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                            <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                            
                            <a href="javascript:;" @click="openInformConsent(v1,k1,k,v.head)" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo ml-2"  v-b-tooltip.hover :title="v1.inform_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                            <i class="icon-file-check2"></i>
                            </a>

                            <a href="javascript:;" @click="openInformConsentDetail(v1,k1,k,v.head)" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                            <i class="icon-file-eye2"></i>
                            </a>
                        
                            </div>
                        </template>
                        </div>
                    </div>
                    </div>
                </template>
                
                <div class="col-md-12">
                  <div class="text-center mt-3">
                    <a href="javascript:;" @click="doOpenOperasi" class="btn alpha-blue"><i
                        class="icon-plus2 mr-2"></i>Tambah Tindakan Operasi</a>
                  </div>
                </div>
                </div>
              </div>
            </div>

          </div>

          <small class="text-info font-weight-semibold">*Perubahan Tindakan Operasi hanya bisa dilakukan sekali. Perubahan ini akan berpengaruh pada kajian-kajian operasi yang akan diisi oleh Dokter dan Perawat.</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="text-right">
            <button type="submit" @click="submitTindakanOperasi" class="btn btn-success btn-labeled btn-labeled-left"
              data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
          </div>
        </div>
      </div>
    </validation-observer>

    
    <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
        <small class="text-primary mt-2">Klik pada salah satu kolom 'Isi Informasi' untuk melakukan perubahan.</small>
        <table class="table table-bordered table-sm table-input">
            <thead>
            <tr>
                <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
                </td>
            </tr>
            <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="isParent.Config.mr.pasienSetuju"
                            v-model="rowIC.aranic_is_pasien_setuju"
                        />
                        <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.aranic_is_pasien_setuju" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                <td width="50%">
                    <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="isParent.Config.mr.persetujuanTindakan"
                            v-model="rowIC.aranic_persetujuan_tindakan"
                        />
                        <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.aranic_persetujuan_tindakan" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                </tr>
            </tfoot>
        </table>
        </b-modal>
    </validation-observer>
        
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.aranic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.aranic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.aranic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.aranic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.aranic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.aranic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.aranic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.aranic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>

    <!-- DETAIL UGD -->
    <b-modal v-model="openModalICDetailUGD" :title="'Detail Informed Consent'" size="lg" ok-only>
      <table class="table table-bordered table-sm table-input">
      <thead>
          <tr>
          <td colspan="3">
              <div class="result_tab">
                  <h4>Nama Tindakan / ICD-9-CM</h4>
                  <p>{{rowIC.auic_name}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <th width="24">No.</th>
          <th>Jenis Informasi</th>
          <th>Isi Informasi</th>
          </tr>
      </thead>
      <tbody>
          <tr>
          <td>1</td>
          <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
          <td>
          {{rowIC.auic_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>2</td>
          <td>Dasar Diagnosis</td>
          <td>
          {{rowIC.auic_dasar_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>3</td>
          <td>Tindakan Kedokteran</td>
          <td>
          {{rowIC.auic_tindakan_dokter||"-"}}
          </td>
          </tr>
          <tr>
          <td>4</td>
          <td>Indikasi Tindakan</td>
          <td>
          {{rowIC.auic_indikasi_tindakan||"-"}}
          </td>
          </tr>
          <tr>
          <td>5</td>
          <td>Tata Cara</td>
          <td>
          {{rowIC.auic_tata_cara||"-"}}
          </td>
          </tr>
          <tr>
          <td>6</td>
          <td>Tujuan</td>
          <td>
          {{rowIC.auic_tujuan||"-"}}
          </td>
          </tr>
          <tr>
          <td>7</td>
          <td>Risiko</td>
          <td>
          {{rowIC.auic_risiko||"-"}}
          </td>
          </tr>
          <tr>
          <td>8</td>
          <td>Komplikasi</td>
          <td>
          {{rowIC.auic_kompilasi||"-"}}
          </td>
          </tr>
          <tr>
          <td>9</td>
          <td>Prognosis</td>
          <td>
          {{rowIC.auic_prognosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>10</td>
          <td>Alternatif & Risiko</td>
          <td>
          {{rowIC.auic_alternatif||"-"}}
          </td>
          </tr>
          <tr>
          <td>11</td>
          <td>Lain-lain</td>
          <td>
          {{rowIC.auic_lainnya||"-"}}
          </td>
          </tr>
      </tbody>
      <tfoot>
          <tr>
          <td width="50%" colspan="2">
              <div class="result_tab">
              <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
              <p v-if="rowIC.auic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
              <p v-else>Tindakan ditolak</p>
              </div>
          </td>
          <td width="50%">
              <div class="result_tab">
              <h4>Persetujuan tindakan diberikan kepada</h4>
              <p v-if="rowIC.auic_persetujuan_tindakan == 'P'">Pasien</p>
              <p v-else>Penanggung Jawab</p>
              </div>
          </td>
          </tr>
      </tfoot>
      </table>
    </b-modal>

    
    <b-modal v-model="openOperasi" :title="'Pilih Item Pemeriksaan Operasi'" size="xl" hide-footer>
      <div class="modal-header d-block p-0 mt-3">
          <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
          <li v-for="(v,k) in dataRowOperasi" :key="k" @click="changeTabOperasi(k)" class="nav-item">
              <a href="javascript:;" data-toggle="tab"
              :class="k == activeTabOperasi ? 'nav-link active' : 'nav-link'">{{v.head}}</a>
          </li>
          </ul>
      </div>

      <div class="modal-body p-0 mt-3">
          <div class="tab-content">
            <div v-for="(v,k) in dataRowOperasi" :key="k+'radform'"
                :class="k == activeTabOperasi ? 'tab-pane fade show active' : 'tab-pane fade'">
                <div class="row sc-row g-0">
                <div class="col-md-3" v-for="(v1,k1) in (v.data||[])" :key="k1+'radio'">
                    <div class="selection-control">
                    <b-form-checkbox @input="changeInpTindakanOperasi($event,v1,k,k1)" :name="'tabrad'+String(v1.id)" v-model="v1['selected']">{{v1.text||"-"}}
                    </b-form-checkbox>
                    <b-form-input type="text" v-if="v1['selected']" v-model="v1['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                    </div>
                </div>
                </div>
            </div>
          </div>
      </div>

      <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
          <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
          <span class="m-0" v-if="selectedOperasiInput">{{selectedOperasiInput}}</span>
          <span v-else> - </span>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white">
          <div>
          <button @click="openOperasi = false" data-dismiss="modal" class="btn">Selesai</button>
          </div>
      </div>
    </b-modal>



  </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default {
  extends: GlobalVue,
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
    dataTindakanOperasi(){
      return (this.isParent.rowEdit.arano_tindakan_operasi_data||[])
    },
    
    selectedOperasiInput() {
      let data = []
      for (let i = 0; i < (this.dataRowOperasi || []).length; i++) {
        for (let j = 0; j < (this.dataRowOperasi[i]['data'] || []).length; j++) {
            if (this.dataRowOperasi[i]['data'][j]['selected']) {
                data.push(this.dataRowOperasi[i]['data'][j]['text'])
            }
        }
      }
      return data.join(", ")
    },

    
    selectedOperasiInformConsent() {
      let data = []
      for (let i = 0; i < (this.dataRowOperasi || []).length; i++) {
          for (let j = 0; j < (this.dataRowOperasi[i]['data'] || []).length; j++) {
              if (this.dataRowOperasi[i]['data'][j]['selected']) {
                  data.push(this.dataRowOperasi[i]['data'][j]['inform_consent'])
              }
          }
      }
      return data
    },
    
    selectedOperasiInputData() {
        let data = []
        for (let i = 0; i < (this.dataRowOperasi || []).length; i++) {
            for (let j = 0; j < (this.dataRowOperasi[i]['data'] || []).length; j++) {
                if (this.dataRowOperasi[i]['data'][j]['selected']) {
                    data.push(this.dataRowOperasi[i]['data'][j])
                }
            }
        }
        return data
    },
    
    validateICOperasi() {
        let isHaveIC = 0
        for (let i = 0; i < (this.selectedOperasiInputData || []).length; i++) {
            if (this.selectedOperasiInputData[i]['inform_consent']) {
                isHaveIC = isHaveIC + 1
            }
        }
        return isHaveIC == (this.selectedOperasiInputData || []).length
    },

  },
  data(){
    return {
      dataRowOperasi: [],
      
      openOperasi: false,
      activeTabOperasi: 0,
      openModalICDetail: false,
      openModalIC: false,
      rowIC: {
        aranic_is_pasien_setuju: null,
        aranic_persetujuan_tindakan: null
      },
      openModalICDetailUGD: false,
    }
  },
  mounted() {
    this.getTindakanOperasi()
  },
  methods: {
    submitTindakanOperasi(){
      this.$refs['VFormTindakanOperasi'].validate().then(success=>{
        if(success){

          if (!this.validateICOperasi) {
              return this.$swal({
                  icon: 'error',
                  title: 'Tidak dapat Lanjut',
                  text: 'Tindakan Operasi Memerlukan Inform Consent'
              })
          }
          
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            text: 'Perubahan Tindakan Operasi hanya bisa dilakukan sekali. Perubahan ini akan berpengaruh pada kajian-kajian operasi yang akan diisi oleh Dokter dan Perawat.',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = {
                arano_tindakan_operasi: this.selectedOperasiInput,
                arano_inform_consent : this.selectedOperasiInformConsent,
                arano_inform_consent_old : this.isParent.rowEdit.arano_inform_consent,
                arano_id : this.isParent.rowEdit.arano_id,
                arano_from : this.isParent.rowEdit.arano_from,
                type: "change-tindakan-operasi"
              }
              this.loadingOverlay = true

              Gen.apiRest(
                  "/do/"+"RanapOperasiPerawatBedah",
                  {data: data}, 
                  "POST"
              ).then(res=>{
                this.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
                this.isParent.openPopupUbahOperasi = false
                this.isParent.apiGet()
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
          
        }
      })
    },
    getTindakanOperasi(){
      this.loadingOverlay = true
      let data = {
        type: "get-tindakan-operasi",
        idOperasi: this.isParent.rowEdit.arano_id,
        arano_tindakan_operasi_draft: this.isParent.rowEdit.arano_tindakan_operasi_draft,
        tindakanOperasi: this.isParent.rowEdit.arano_tindakan_operasi_data
      }
      Gen.apiRest(
          "/do/"+"RanapOperasiPerawatBedah",
          {data: data}, 
          "POST"
      ).then(res=>{
        this.loadingOverlay = false
        this.dataRowOperasi = res.data.dataRowOperasi

        console.log(this.isParent.rowEdit.arano_from)
      })
    },
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },
    doOpenOperasi() {
      this.openOperasi = true
    },
    changeTabOperasi(e) {
      this.activeTabOperasi = e
    },
    isShowHeadOperasi(j) {
      let isData = 0
      for (let k = 0; k < (this.dataRowOperasi[j]['data'] || []).length; k++) {
        if (this.dataRowOperasi[j]['data'][k]['selected']) {
            isData += 1
        }
      }
      return isData
    },    
    isShowOperasi(j, k) {
        let isData = 0
        if (this.dataRowOperasi[j]['data'][k]['selected']) {
            isData += 1
        }
        return isData
    },

    openInformConsentDetail(v,k1,k,kat){
        Gen.apiRest('/do/' + 'RanapCPPTDokter', {
            data: {
                type: 'get-data-ic',
                id: v.inform_consent,
                isOperasi: true,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.tindakan = this.rowIC.aranic_name || v.text  + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent
              
            this.rowIC.k1 = k1
            this.rowIC.k = k

            this.openModalICDetail = true                   
        })
    },
    

    openInformConsentDetailUGD(v){
      Gen.apiRest('/do/' + 'UGDFormDokter', {
          data: {
              type: 'get-data-ic',
              id: v.inform_consent,
          }
      }, 'POST').then(res => {
          this.rowIC = res.data.row 
          this.rowIC.tindakan = this.rowIC.auic_name
          this.rowIC.id_tindakan = v.inform_consent
          this.openModalICDetailUGD = true                   
      })
    },
    openInformConsent(v,k1,k,kat){
        Gen.apiRest('/do/' + 'RanapCPPTDokter', {
            data: {
                type: 'get-data-ic',
                id: v.inform_consent,
                isOperasi: true,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.tindakan = v.text + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent   
            this.rowIC.k1 = k1
            this.rowIC.k = k         
        })
        
        this.openModalIC = true
    },

    
    changeInpTindakanOperasi(e,v,kU,k){
      if(!e && v.inform_consent){
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda yakin ingin menghapus tindakan ini, Tindakan telah mempunyai Inform consent?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
              if(result.value){
                  this.loadingOverlay = true                        
                  let data = {
                      type: "remove-ic-single",
                      id: v.inform_consent
                  }
                  Gen.apiRest(
                      "/do/"+'RanapCPPTDokter', 
                      {
                          data: data
                      }, "POST"
                  ).then(()=>{
                      this.loadingOverlay = false                                            
                      this.dataRowOperasi[kU]['data'][k]['inform_consent'] = null                    
                  })
              }
              else{
                  this.dataRowOperasi[kU]['data'][k]['selected'] = true

              }
          })
      }
    },

    submitInformConcent(){
        this.$refs['VFormInformconsent'].validate().then(success => {
            if(success){
                this.openModalIC = false

                let data = this.rowIC
                data.type = 'submit-data-inform-consent-single'
                data.aranic_aranr_id = this.isParent.rowEdit.arano_aranr_id
                data.aranic_name = this.rowIC.tindakan
                data.aranic_created_on = 'Operasi'

                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'RanapOperasiPerawatBedah', {
                  data: data
                },
                "POST"
                ).then(res => {
                    this.dataRowOperasi[this.rowIC.k]['data'][this.rowIC.k1]['inform_consent'] = res.data
                    this.loadingOverlay = false
                    this.rowIC.aranic_id = null
                    
                    Gen.apiRest(
                        "/do/"+'RanapOperasiPerawatBedah',
                        {data:{
                          type : "update-draft",
                          arano_id : this.isParent.rowEdit.arano_id,
                          arano_tindakan_operasi_draft: this.dataRowOperasi
                        }}, 
                        "POST"
                    )

                    this.isParent.apiGet()
                    return this.$swal({
                        title: "Data Informed Consent berhasil disimpan",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        })
    },
  }
}

</script>

